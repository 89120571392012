import React from 'react';

const Modal = ({ isActive, children, labelledby }) => {
    if (!isActive) return null;

    return (
        <div className={`modal fade modal-lg modal-dialog-scrollable ${isActive ? 'show' : ''}`} tabIndex="-1" style={{ display: isActive ? 'block' : 'none' }} aria-labelledby={labelledby}>
            <div className="modal-dialog">
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;