import "../js/jquery.min.js";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_Basic_StudInfo,
    API_GET_StuStuBase,
    API_GET_StuWish
} from '../global/constants';

function teacher_Office() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [classID, setClassID] = useState("");//班級
    const [stuList, setStuList] = useState([]);//學生清單
    //查詢學生詳細資料
    const [stuNo, setStuNo] = useState("");//學生ID編號
    const [stuName, setStuName] = useState("");//學生名字
    const [studDeptNo, setStudDeptNo] = useState("");//學生報考類群
    //const [studSID, setStudSID] = useState("F132201117");

    //學生志願
    const [sysWishList, setSysWishList] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("isLogin") != "1") {
            alert("登入已失效")
            localStorage.clear();
            return window.location.href = "/";
        }
        //查驗token
        let qqq = localStorage.getItem("token").split(".");
        try {
            var arq = JSON.parse(decodeURIComponent(escape(window.atob(qqq[1].replace(/-/g, "+").replace(/_/g, "/")))));
            if (arq && Object.keys(arq)) {
                if (arq.type != "teacher") {
                    localStorage.clear();
                    alert("無效登入");
                    return history.push("/");
                }
            } else {
                throw "無效token"
            }
        } catch (e) {
            localStorage.clear();
            alert("登入失效");
            return window.location.href = "/";
        }

        //從登入token取得哪位老師
        let usDB = JSON.parse(localStorage.getItem('UserData'));
        setClassID(usDB.classNo);
        getData(usDB.classNo);
    }, []);

    function getData(clid) {
        clid = clid || classID;
        API_GET_StuStuBase({
            "StudClass": clid
        })
            .then((response) => response.data)
            .then((data) => {

                console.log(data);
                if (data.code == "0000") {
                    setStuList(data.result);

                } else if (data.code == "1001") {
                    alert("登入驗證失效");
                    localStorage.clear();
                    return window.location = "/";
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function callStuData(target) {
        setStuNo(target);
        API_GET_StuWish({
            "StudNo": target
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code === "") {
                    //alert("學生尚未建立志願，請學生先建立填寫");
                    $("#sys_checkStu").modal("show"); 
                    return setSysWishList([]);
                }
                if (data.code == "0000") {
                    setSysWishList(data.result);
                    
                    $("#sys_checkStu").modal("show");
                } else if (data.code == "1001") {
                    alert("登入驗證失效");
                    localStorage.clear();
                    return window.location = "/";
                } else {
                    setSysWishList([]);
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

        API_Get_Basic_StudInfo({
            "StudNo": target
        }).then((response) => response.data)
            .then((data) => {

                console.log(data);
                if (data.code == "0000") {
                    if (data.result.length > 0) {
                        let person = data.result[0];
                        setStuName(person.StudName);
                        setStudDeptNo(`${person.StudDeptNo} ${person.DeptName}`);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    return (
        <>
            <div className="container-sm " style={{ height: "100vh", weight: "100vw", paddingTop: "5rem", paddingBottom: "3rem", maxWidth: "996px" }} >
                <div className="" style={{ position: "absolute", top: "0", left: "0", right: "0", margin: "0 auto" }} >callKind</div>
                <div className="position-relative" style={{ width: "-webkit-fill-available", height: "-webkit-fill-available", overflowY: "auto", overflowX: "hidden" }}>

                    <div className="row p-md-4 p-2 justify-content-around align-items-center">
                        <div className="col-md-auto col-12 pb-3 pb-md-0">
                            <div>
                                班級：{classID}
                            </div>
                            <div className="row justify-content-start">
                                {stuList == null || stuList.length == 0 || !classID ?
                                    <div className="col-3 p-2 text-center">無資料</div> :
                                    stuList.map((value, key) => (
                                        <div className="col-3 p-2 text-center" key={key}>
                                            <button className="btn btn-link" type="button" onClick={(e) => callStuData(value.studNo)} >{value.studNo} {value.studName}</button>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*查詢學生選填*/}
            <div className="modal fade modal-dialog-scrollable" id="sys_checkStu" tabIndex="-1" aria-labelledby="staticcheckSch" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header align-items-start pb-0 border-0">
                            <h1 className="modal-title h5 fw-bold" id="staticcheckSch">
                                學生學生統測報名資料
                            </h1>
                            <button type="button" className="btn-close mt-0" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-header align-items-start py-0 border-0 mx-0">
                            <div className="border-bottom border-primary-subtle pb-2 mb-2 w-100">
                                <div className="mt-2 row">
                                    <div className="col-auto d-flex align-items-center fw-bold">
                                        學號：
                                    </div>
                                    <div className="col-auto">
                                        {stuNo}
                                    </div>
                                </div>
                                <div className="mt-2 row">
                                    <div className="col-auto d-flex align-items-center fw-bold">
                                        姓名：
                                    </div>
                                    <div className="col-auto">
                                        {stuName}
                                    </div>
                                </div>
                                {/*<div className="mt-2 row">*/}
                                {/*    <div className="col-auto d-flex align-items-center fw-bold">*/}
                                {/*        身分證號：*/}
                                {/*    </div>*/}
                                {/*    <div className="col-auto">*/}
                                {/*        {studSID}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="mt-2 row">
                                    <div className="col-auto d-flex align-items-center fw-bold">
                                        統測報名類別：
                                    </div>
                                    <div className="col-auto">
                                        {studDeptNo}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body pt-0">
                            <table className="table table-striped table-light rounded overflow-hidden">
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: "#cfe2ff" }} >志願</th>
                                        <th style={{ backgroundColor: "#cfe2ff" }}>校系科組志願資料</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sysWishList == null || sysWishList.length == 0 ? <tr><td colSpan="2">未填寫</td></tr> :
                                        sysWishList.map((value, key) => (
                                            <tr key={key} >
                                                <td>{(key + 1)}</td>
                                                <td>{`${value.StudSchSubNo} ${value.SchName} ${value.SchSubName}`}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*END 查詢學生選填*/}
        </>
    );
}


export default teacher_Office;
