import "../../js/jquery.min.js";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import {
    API_GetAll_TeacherTrainingCategory,
    API_Search_TeacherTraining,
    API_Download_TeacherTraining,
    API_GetAll_TeacherTrainingAcademicYear
} from '../../global/constants.js';
import moment from 'moment';
import { checkAuthToken } from '../../global/TokenUtil.js';

function SeminarSearchSystem() {
    const history = useHistory();

    const [teacher, setTeacher] = useState("");
    const [academicYears, setAcademicYears] = useState([{ id: null, name: "全部" }]);
    const [teacherTrainingCategories, setTeacherTrainingCategories] = useState([{ id: null, name: "全部" }]);

    const [academicYearSelected, setAcademicYearSelected] = useState(null);
    const [teacherTrainingCategorySelected, setTeacherTrainingCategorySelected] = useState(null);

    const [dataList, setDataList] = useState([]);

    function getTeacherTrainingCategory() {
        API_GetAll_TeacherTrainingCategory()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setTeacherTrainingCategories([...teacherTrainingCategories, ...data.result]);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                alert("未知錯誤");

            })
    }


    function getAcademicYear() {
        API_GetAll_TeacherTrainingAcademicYear()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setAcademicYears([...academicYears, ...data.result]);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }


    const handleInputChange = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case 'teacher':
                setTeacher(value);
                break;
            case 'academicYearSelected':
                setAcademicYearSelected(value);
                break;
            case 'teacherTrainingCategorySelected':
                setTeacherTrainingCategorySelected(value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = () => {
        var dt = {
            teacherName: teacher,
            academicYear: academicYearSelected,
            teacherTrainingCategory: teacherTrainingCategorySelected
        }

        API_Search_TeacherTraining(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setDataList(data.result);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push('/');
                }
                else if (err.response.status === 400) {
                    alert("客戶端錯誤，請檢查必填欄位！");
                }
            })
    }

    const handleDownload = (filename, sourceName) => {
        const FileDownload = require("js-file-download");

        API_Download_TeacherTraining({
            "fileName": filename,
            "sourceName": sourceName
        })
            .then((response) => {
                console.log(response);
                FileDownload(response.data, sourceName);
            })
            .catch(err => {
                console.log(err);
                alert("無檔案可下載");
            })
    }

    const initPageProp = () => {
        getTeacherTrainingCategory();
        getAcademicYear();
    }

    useEffect(() => {
        initPageProp();
    }, []);


    useEffect(() => {
        console.log(dataList);
    }, [dataList])

    return (
        <>
            <div className="container mt-4">
                <h2>臺北市立松山家商心得共享平台</h2>
                <div className="mt-4">
                    <div className="row">
                        <div className="col">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div class="row g-3 mb-3">
                                        <div className="col-sm-6 form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="teacher"
                                                placeholder="研習教師"
                                                value={teacher}
                                                onChange={handleInputChange}
                                                name="teacher"
                                            />
                                            <label for="teacher">研習教師</label>
                                        </div>

                                        <div className="col form-floating">
                                            <select className="form-select mb-2" aria-label="Default select example" id={"academicYear"} value={academicYearSelected} onChange={handleInputChange} name={"academicYearSelected"}>
                                                <option defaultValue="" disabled>學年度</option>
                                                {academicYears.map((item, index) => <option value={item.name}>{item.name}</option>)}
                                            </select>

                                            <label for="academicYear">學年度</label>
                                        </div>

                                        <div className="col form-floating">
                                            <select className="form-select mb-2" aria-label="Default select example" id={"teacherTrainingCategory"} value={teacherTrainingCategorySelected} onChange={handleInputChange} name={"teacherTrainingCategorySelected"}>
                                                <option defaultValue="" disabled>研習類別</option>
                                                {teacherTrainingCategories.map((item, index) => <option value={item.name}>{item.name}</option>)}
                                            </select>

                                            <label for="examCatrgory">研習類別</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-footer border-0 bg-white d-md-flex justify-content-md-end">
                                    <button className="btn btn-primary p-3" type="submit" onClick={handleSubmit}>按一下，開始搜尋研習心得</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div className="mt-4">
                    <p className="mb-3">篩選結果</p>
                    <table className="table table-striped table-hover table-bordered table-bordered-dark">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col">編號</th>
                                <th scope="col">學年度</th>
                                <th scope="col">研習教師</th>
                                <th scope="col">研習類別</th>
                                <th scope="col">研習日期</th>
                                <th scope="col">研習時數</th>
                                <th scope="col">研習心得</th>
                                <th scope="col">研習內容概述</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.academicYear}</td>
                                    <td>{item.teacherName}</td>
                                    <td>{item.teacherTrainingCategory}</td>
                                    <td>{item.trainingBeginDate} {item.trainingEndDate == "" || item.trainingEndDate == null ? "" : " ~ " + item.trainingEndDate}</td>
                                    <td>{item.trainingHour}</td>
                                    <td><a href="#" onClick={() => handleDownload(item.trainingThoughtsFilePath, item.trainingThoughtsFileName)}>研習心得</a></td>
                                    <td>{item.paperIllustrate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}


export default SeminarSearchSystem;
