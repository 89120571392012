import "../js/jquery.min.js";
//import "../css/style.css";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_Basic_StudInfo,
    API_GET_StuWish,
    API_POST_StuWishUpdate,
    API_GET_StuSchList,
    API_GET_StuSubList
} from '../global/constants';

import { useReactToPrint } from 'react-to-print';
//參考 https://github.com/gregnb/react-to-print
//列印內容編輯
class ComponentToPrint extends React.PureComponent {

    render() {
        const { stuNo, stuName, studDeptNo, sysWishList } = this.props;

        return (
            <div className="container">
                <div className="row p-md-4 p-2 justify-content-around align-items-center">
                    <div className="col-md-auto col-12 pb-3 pb-md-0">
                        <div className="row justify-content-start">
                            <div className="col-12 py-2">
                                <span className="fw-bold">學號：</span>
                                {stuNo}
                            </div>
                            <div className="col-12 py-2">
                                <span className="fw-bold">姓名：</span>
                                {stuName}
                            </div>
                            <div className="col-12 py-2">
                                <span className="fw-bold"> 統測報名群(類)別：</span>
                                {studDeptNo}
                            </div>
                            <hr className="col-12" />
                            <div className="col-12">
                                <table className="table table-striped table-light rounded overflow-hidden">
                                    <thead>
                                        <tr>
                                            <th style={{ backgroundColor: "#cfe2ff" }}>校系科組志願資料</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sysWishList == null || sysWishList.length == 0 ? <tr><td colSpan="3">未填寫</td></tr> :
                                            sysWishList.map((value, key) => (
                                                <tr key={key} >
                                                    <td>{`${value.StudSchSubNo} ${value.SchName} ${value.SchSubName}`}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function student_Office() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    //SVG
    const DelSvg = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
        </svg>
    )
    //SVG END
    //志願數量
    const [wishLength, setWishLength] = useState(0);
    //學生基本資料
    const [stuNo, setStuNo] = useState("");
    const [stuName, setStuName] = useState("");
    const [studDeptCode, setStudDeptCode] = useState("");
    const [studDeptNo, setStudDeptNo] = useState("");
    const [studClass, setSstudClass] = useState("");

    //已編輯 or 啟用列印
    const [isEdit, setIsEdit] = useState(false);
    //列印志願
    const [sysWishList, setSysWishList] = useState([]);
    //填寫志願
    const [wellingList, setWellingList] = useState([]);

    //學校
    const [schoolSearch, setSchoolSearch] = useState({});//選擇學校
    const [schBaseList, setSchBaseList] = useState([]);//學校清單
    const [schCata, setSchCata] = useState([]);//學校所屬科系
    const [selectCata, setSelectCata] = useState({});//選擇科系
    //可填數量限制
    const [overJoin, setOverJoin] = useState(false);
    //時間限制
    const [overTime, setOverTime] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("isLogin") != "1") {
            alert("登入已失效")
            localStorage.clear();
            return window.location.href = "/";
        }

        //查驗token
        let qqq = localStorage.getItem("token").split(".");
        try {
            var arq = JSON.parse(decodeURIComponent(escape(window.atob(qqq[1].replace(/-/g, "+").replace(/_/g, "/")))));
            if (arq && Object.keys(arq)) {
                console.log(arq);
                if (arq.type != "student") {
                    localStorage.clear();
                    alert("無效登入");
                    return history.push("/");
                }
                setWishLength(parseInt(arq.wish));
            } else {
                throw "無效token"
            }
        }
        catch (e) {
            localStorage.clear();
            alert("登入失效");
            return window.location.href = "/";
        }
        //從登入token取的哪位學生登入
        let usDB = JSON.parse(localStorage.getItem('UserData'));

        setStuNo(usDB.ckStud.studNo);
        getData(usDB.ckStud.studNo);
        getWish(usDB.ckStud.studNo);

    }, []);

    //抓資料
    function getData(stu) {
        stu = stu || stuNo
        API_GET_StuSchList()
            .then((response) => response.data)
            .then((data) => {

                let usDB = JSON.parse(localStorage.getItem('UserData'));

                if ((new Date() > new Date(usDB.examSchd.exSchdEndDate))) {
                    setOverTime(true);
                    //document.getElementById("join").disabled = true;
                    //document.getElementById("remove").disabled = true;
                    //document.getElementById("update").disabled = true;
                }

                if (data.code == "0000") {
                    setSchBaseList(data.result);
                    setIsEdit(false);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
        API_Get_Basic_StudInfo({
            "StudNo": stu
        }).then((response) => response.data)
            .then((data) => {
                //todo因為有改API 所以這邊要改
                //當年度結束時間 大於 現在時間這時候 不允許按 加入/刪除/確認送出 

                if (data.code == "0000") {
                    if (data.result.length > 0) {
                        let person = data.result[0];
                        setStuName(person.StudName);
                        setStudDeptCode(person.StudDeptNo);
                        setStudDeptNo(`${person.StudDeptNo} ${person.DeptName}`);
                        setSstudClass(person.StudClass);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    function getWish(stu) {
        stu = stu || stuNo
        API_GET_StuWish({
            "StudNo": stu
        })
            .then((response) => response.data)
            .then((data) => {

                console.log(data);
                if (data.code == "") {
                    setSysWishList([]);
                    return setIsEdit(false);
                }
                if (data.code == "0000") {
                    let filterJson = data.result.map(x => {
                        return {
                            "DeptNo": x.StudDeptNo,
                            "DeptName": x.DeptName,
                            "SchSubNo": x.StudSchSubNo,
                            "SchSubName": x.SchSubName,
                            "SchNo": x.StudSchSubNo.slice(0, 3),
                            "schName": x.SchName
                        };
                    })
                    setWellingList(filterJson);
                    if (data.result) {
                        setSysWishList(data.result);
                    } else {
                        setSysWishList([]);
                    }
                    setIsEdit(false);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //選取學校
    function changeSchSearch(e) {
        if (!e.target.value) { return null }
        let val = e.target.value;
        let ary = schBaseList.find(x => x.schNo == val);
        console.log(ary);
        setSelectCata("")
        if (Object.keys(ary).length > 0) {
            setSchoolSearch(ary);
            getSchCheck(ary.schNo);
        } else {
            setSchoolSearch({});
            setSchCata([]);
        }

    }
    //查詢學校系所
    function getSchCheck(getSchNo) {
        if (!getSchNo) { return alert("請選擇清單內資料") }
        API_GET_StuSubList({
            "SchNo": getSchNo,
            "DeptNo": studDeptCode
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    setSchCata(data.result);
                } else if (data.code == "1001") {
                    alert("登入驗證失效");
                    localStorage.clear();
                    return window.location = "/";
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    //科系選擇
    function changeCata(e) {
        if (!e.target.value) { return null }
        let val = e.target.value;
        let ary = schCata.filter(x => { return x.SchSubNo == val });
        if (ary.length > 0) {
            setSelectCata(ary[0])
        } else {
            setSelectCata("")
        }
    }

    //選擇科系 檢查超過可用數量
    useEffect(() => {


        if (wishLength > wellingList.length) {
        } else { return setOverJoin(true); }
        if (Object.keys(schoolSearch).length > 0) {
            let ary = schBaseList.find(x => x.schNo == schoolSearch.schNo);
            if (ary && ary.schNo) {
                let arq = ary.schNo
                let countAry = wellingList.reduce((acc, item) => {
                    const prefix = String(item.SchSubNo).slice(0, 3);
                    acc[prefix] = (acc[prefix] || 0) + 1;
                    return acc;
                }, {});
                if (countAry[arq] && countAry[arq] >= ary.schWishCnt) {
                    return setOverJoin(true);
                }
            }
        }
        setOverJoin(false);
    }, [schoolSearch, wellingList]);

    //新增志願
    function addWish() {
        /*if (overJoin) { return alert("超過可報考數量") }*/
        //console.log(selectCata)
        if (Object.keys(selectCata).length == 0) { return alert("請選擇清單內資料"); }
        if (wishLength > wellingList.length) {
        } else { return null }
        if (wellingList.find(x => x.SchSubNo == selectCata.SchSubNo)) { return alert("已新增志願") }
        let ary = schBaseList.find(x => x.schNo == selectCata.SchNo);
        //console.log(ary);
        let wql = selectCata;
        wql.schName = ary.schName;

        setWellingList([
            ...wellingList,
            wql
        ]);
        setIsEdit(true);
    }
    //刪除志願
    function removeWish(e) {
        if (!e) { return null }
        setWellingList(wellingList.filter(item => item.SchSubNo !== e));
        setIsEdit(true);
    }

    //確認送出
    function updateWish() {
        let returnJson = wellingList.map((x, i) => {
            return {
                "studNo": stuNo,// 學生編號
                "studClass": studClass, //學生教室
                "studWishOrd": i,//志願順序
                "studSchSubNo": x.SchSubNo//學校系所編號
            }
        });
        //todo 檢查returnJosn 為什麼是空
        if (returnJson.length == 0 || returnJson == null) { returnJson = []; }
        //console.log(returnJson);
        API_POST_StuWishUpdate({
            studNo: stuNo,
            studWishes: returnJson
        })
            .then((response) => response.data)
            .then((data) => {

                console.log(data);
                if (data.code == "0000") {
                    alert("儲存成功")
                    getWish();
                    //window.location.href = window.location.href;
                } else if (data.code == "1001") {
                    alert("登入驗證失效");
                    localStorage.clear();
                    return window.location = "/";
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //列印功能
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    async function callPrinter() {
        if (isEdit) { return alert("請先確認送出後再列印") }

        //重新call api
        handlePrint();
    }
    //END 列印功能


    return (
        <>
            <div className="container-sm pb-0 pb-md-5" style={{ height: "100vh", weight: "100vw", paddingTop: "5rem", maxWidth: "996px" }} >
                <div className="" style={{ position: "absolute", top: "0", left: "0", right: "0", margin: "0 auto" }} >callKind</div>
                <div className="position-relative" style={{ width: "-webkit-fill-available", height: "-webkit-fill-available", overflowY: "auto", overflowX: "hidden" }}>

                    <div className="row p-md-4 p-2 justify-content-around align-items-center">
                        <div className="col-md-auto col-12 pb-3 pb-md-0">
                            <div className="row justify-content-start">
                                <div className="col-12 py-2">
                                    <span className="fw-bold">學號：</span>
                                    {stuNo}
                                </div>
                                <div className="col-12 py-2">
                                    <span className="fw-bold">姓名：</span>
                                    {stuName}
                                </div>
                                <div className="col-12 py-2">
                                    <span className="fw-bold"> 統測報名群(類)別：</span>
                                    {studDeptNo}
                                </div>
                                <div className="col-12 py-2">
                                    <div className="row">
                                        <div className="col-2 col-md-auto  d-flex align-items-center">
                                            <span className="fw-bold">學校： </span>
                                        </div>
                                        <div className="col-10 col-md-auto  d-flex align-items-center">
                                            <select className="form-control w-auto" value={schoolSearch.schNo} onChange={changeSchSearch}  >
                                                <option value="">選擇學校</option>
                                                {schBaseList == null || schBaseList.length == 0 ? <option disabled>無可用選項</option> :
                                                    schBaseList.map((x, i) =>
                                                        <option key={i} value={x.schNo} >{`${x.schNo} ${x.schName}`}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="offset-2 offset-md-0 col-md-auto  d-flex align-items-center">
                                            該校可填報系科數量：{schoolSearch.schWishCnt || "--"}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 py-2">
                                    <div className="row">
                                        <div className="col-auto  d-flex align-items-center">
                                            <span className="fw-bold">科系：  </span>
                                        </div>
                                        <div className="col-auto  d-flex align-items-center">
                                            <select value={selectCata.SchSubNo || ""} className="form-select w-auto" onChange={changeCata} >
                                                <option value="">選擇科系</option>
                                                {
                                                    schCata == null || schCata.length == 0 ? null :
                                                        schCata.map((x, i) =>
                                                            <option key={i} value={x.SchSubNo} >{`${x.SchSubNo} ${x.SchSubName}`}</option>
                                                        )
                                                }
                                            </select>
                                        </div>
                                        <div className="col-auto  d-flex align-items-center">
                                            <button id="join" className="btn btn-primary w-auto" onClick={addWish} disabled={overJoin || overTime} >加入</button>
                                        </div>
                                    </div>
                                </div>
                                <hr className="col-12" />
                                <div className="col-12">
                                    <table className="table table-striped table-light rounded overflow-hidden">
                                        <thead>
                                            <tr>
                                                <th style={{ backgroundColor: "#cfe2ff" }} >志願</th>
                                                <th style={{ backgroundColor: "#cfe2ff" }}>校系科組志願資料</th>
                                                <th style={{ backgroundColor: "#cfe2ff" }}>刪除</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wellingList == null || wellingList.length == 0 ?
                                                null :
                                                wellingList.map((value, key) => (
                                                    <tr key={key} >
                                                        <td>{(key + 1)}</td>
                                                        <td>{`${value.SchSubNo} ${value.schName} ${value.SchSubName}`}</td>
                                                        <td>
                                                            <button id="remove" type="button" className="btn btn-outline-danger" onClick={(e) => removeWish(value.SchSubNo)} disabled={overTime} >
                                                                <DelSvg />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}

                                            {Array.from({ length: (wishLength - wellingList.length) }, (_, i) =>
                                                <tr><td colSpan="3">&nbsp;</td></tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <hr className="col-12" />
                                <div className="col-12 pb-5">
                                    <button id="update" type="button" className="btn btn-primary mx-2" disabled={!isEdit || overTime} onClick={updateWish}>確認送出</button>

                                    <button type="button" className="btn btn-primary mx-2" disabled={isEdit} onClick={callPrinter} >列印</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-none" style={{ display: "none" }} >
                    <ComponentToPrint stuNo={stuNo} stuName={stuName} studDeptNo={studDeptNo} sysWishList={sysWishList} ref={componentRef} />
                </div>
            </div>
        </>
    );
}


export default student_Office;
