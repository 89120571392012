import React, { Component } from "react";
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import zh from 'date-fns/locale/zh-TW';
import moment from 'moment';
registerLocale('zh-tw', zh);

function y4mdTomoment(y4md, mode) {
    if (y4md === undefined || y4md === null || y4md.trim() === '')
        return null;

    // 特殊日期符號，TODAY,TOMORROW,YESTERDAY
    if (y4md === 'TODAY')
        return moment().startOf('date').format("YYYY/MM/DD");
    if (y4md === 'TOMORROW')
        return moment().add(1, 'day').startOf('date').format("YYYY/MM/DD");
    if (y4md === 'YESTERDAY')
        return moment().subtract(1, 'day').startOf('date').format("YYYY/MM/DD");

    // default: YYYY/MM/DD格式
    return new Date(moment(new Date(y4md)).format("YYYY/MM/DD"));

}

function momentToy4md(date, mode) {
    //console.log('momentToy4md', date, mode)
    if (date === undefined || date === null)
        return '';
    date = moment(date);
    if (date.isValid() && moment.isMoment(date)) {
        return date;
    }
}

// resource
let arrYear = []
for (let i = 100; i <= 200; i++) {
    arrYear.push({
        key: `民國${i}年`,
        value: i + 1911
    });
}

const arrMonth = [
    { value: 0, key: '一月' },
    { value: 1, key: '二月' },
    { value: 2, key: '三月' },
    { value: 3, key: '四月' },
    { value: 4, key: '五月' },
    { value: 5, key: '六月' },
    { value: 6, key: '七月' },
    { value: 7, key: '八月' },
    { value: 8, key: '九月' },
    { value: 9, key: '十月' },
    { value: 10, key: '十一月' },
    { value: 11, key: '十二月' }
];

function formatValue(value) {
    //console.log(value);
    let year = new Date(value);
    year = year.getFullYear() - 1911;
    return year + '/MM/dd';
}

/// 民國年日期選取器。注意：顯示民國年日期，但值為西元年日期。
const DatePicker4Tw = ({ name, value, onChange, disabled, mode, minDate, maxDate, readOnly,className }) => (
    <DatePicker
        selected={y4mdTomoment(value, mode)}
        onChange={(date) => onChange(name, momentToy4md(date, mode))}
        disabled={disabled}
        readOnly={readOnly}
        locale="zh-tw"
        dateFormat={formatValue(y4mdTomoment(value, mode))}
        showYearDropdown={true}
        showMonthDropdown={true}
        isClearable={!disabled && !readOnly}
        dropdownMode="select"
        todayButton={'今天'}
        placeholderText={'民國 年/月/日'}
        className={className}
        minDate={y4mdTomoment(minDate, mode)}
        maxDate={y4mdTomoment(maxDate, mode)}
        onKeyDown={(e) => {
            e.preventDefault();
        }}
        onChangeRaw={(e) => {
            //console.log('onChangeRaw')
            const value = e.target.value;
            const valueMoment = moment(new Date(value));
            const isValid = valueMoment.isValid();
            //console.log('→', value, isValid, valueMoment.format('YYYY/MM/DD'));
            if (isValid && moment.isMoment(valueMoment)) {
                onChange(name, momentToy4md(valueMoment, mode));
            }
        }}
        renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
        }) => {
            return (
                <div>
                    <div>
                        <button
                            type="button"
                            className="react-datepicker__navigation react-datepicker__navigation--previous"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                        >
                            Previous Month
                        </button>
                        <button
                            type="button"
                            className="react-datepicker__navigation react-datepicker__navigation--next"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                        >
                            Next month
                        </button>
                        <div className="react-datepicker__current-month react-datepicker__current-month--hasYearDropdown react-datepicker__current-month--hasMonthDropdown">
                            {arrMonth[date.getMonth()].key} {arrYear[arrYear.map(x => x.value).indexOf(date.getFullYear())].key}
                        </div>
                    </div>
                    <div>
                        <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--select">
                            <div className="react-datepicker__month-dropdown-container react-datepicker__month-dropdown-container--select">
                                <select className="react-datepicker__month-select" value={date.getMonth()} onChange={(e) => changeMonth(e.target.value)}>
                                    {arrMonth.map((item, index) => (
                                        <option key={index} value={item.value}>
                                            {item.key}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="react-datepicker__year-dropdown-container react-datepicker__year-dropdown-container--select">
                                <select className="react-datepicker__year-select" value={date.getFullYear()} onChange={(e) => changeYear(e.target.value)}>
                                    {arrYear.map((item, index) => (
                                        <option key={index} value={item.value}>
                                            {item.key}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }}
    />
)

DatePicker4Tw.defaultProps = {
    value: '',
    mode: 'YYYY/MM/DD',
    disabled: false,
    readOnly: false,
}

DatePicker4Tw.propTypes = {
    name: PropTypes.string, // state id-name
    value: PropTypes.string,  // YYYY/MM/DD, YYYYMMDD
    mode: PropTypes.string,  // Y4MD, YMD8
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func
}

export default DatePicker4Tw;
