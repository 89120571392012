import "../../js/jquery.min.js";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import {
    API_Create_TeacherTrainingAcademicYear,
    API_Create_TeacherTrainingCategory,
    API_Delete_TeacherTrainingAcademicYear,
    API_Delete_TeacherTrainingCategory,
    API_GetAll_TeacherTrainingAcademicYear,
    API_GetAll_TeacherTrainingCategory
} from '../../global/constants';
import moment from 'moment';
import { checkAuthToken } from '../../global/TokenUtil';
import "react-datepicker/dist/react-datepicker.css";

function SeminarAdminCategoryInfo() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const [usDB, setUSDB] = useState({});

    //環境數值
    const [curSemester, setCurSemester] = useState("");//112
    const [curAvaStart, setCurAvaStart] = useState("");//2023/5/1
    const [curAvaEnd, setCurAvaEnd] = useState("");//2023/5/30
    const [curWishLength, setCurWishLength] = useState("");//6
    const [curAvaStartTime, setCurAvaStartTime] = useState("");//12:00
    const [curAvaEndTime, setCurAvaEndTime] = useState("");//12:00

    const [isReGet, setIsReGet] = useState(false);
    //科別
    const [inputCategory, setInputCategory] = useState("");
    const [categoryList, setCategoryList] = useState([]);

    //學年度
    const [inputAcademicYear, setInputAcademicYear] = useState("");
    const [academicYearList, setAcademicYearList] = useState([]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case 'inputCategory':
                setInputCategory(value);
                break;
            case 'inputAcademicYear':
                setInputAcademicYear(value);
                break;
            default:
                break;
        }
    }

    const handleSubmitCategory = () => {
        let dt = {
            name: inputCategory
        }

        API_Create_TeacherTrainingCategory(dt)
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    AfterCategoryCreateHandle();
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    // localStorage.clear();
                    // history.push('/');
                }
            })
    }

    const handleDeleteCategory = (id) => {
        var dt = {
            id: id,
        }

        API_Delete_TeacherTrainingCategory(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    const filteredItems = categoryList.filter(item => item.id !== dt.id);
                    setCategoryList(filteredItems);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    const handleSubmitAcademicYear = () => {
        let dt = {
            name: inputAcademicYear
        }

        API_Create_TeacherTrainingAcademicYear(dt)
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    AfterAcademicYearCreateHandle();
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    // localStorage.clear();
                    // history.push('/');
                }
            })
    }

    const handleDeleteAcademicYear = (id) => {
        var dt = {
            id: id,
        }

        API_Delete_TeacherTrainingAcademicYear(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    const filteredItems = academicYearList.filter(item => item.id !== dt.id);
                    setAcademicYearList(filteredItems);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }


    function AfterCategoryCreateHandle() {
        setInputCategory("");
        setIsReGet(true);
    }

    function AfterAcademicYearCreateHandle() {
        setInputAcademicYear("");
        setIsReGet(true);
    }

    function getCategory() {
        API_GetAll_TeacherTrainingCategory()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setCategoryList(data.result);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getAcademicYear() {
        API_GetAll_TeacherTrainingAcademicYear()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setAcademicYearList(data.result);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    useEffect(() => {
        tokenHandle();
        getCategory();
        getAcademicYear();
    }, []);

    useEffect(() => {
        if (isReGet == true) {
            getCategory();
            getAcademicYear();
            setIsReGet(false);
        }
    }, [isReGet])

    function tokenHandle() {
        if (localStorage.getItem('isLogin')) {
            //查驗token
            if (jwtCheck()) {
                let usDB = JSON.parse(localStorage.getItem('UserData'));
                if (!usDB) {

                } else {
                    jwtToSet(usDB);
                }
            }
        } else {
            history.push("/")
        }
    }

    //token解析
    function jwtCheck(inputToken) {
        let qqq = localStorage.getItem("token").split(".");
        if (inputToken) {
            qqq = inputToken.split(".");
        }
        try {
            var arq = JSON.parse(decodeURIComponent(escape(window.atob(qqq[1].replace(/-/g, "+").replace(/_/g, "/")))));
            if (arq && Object.keys(arq)) {
                if (arq.type != "admin") {
                    alert("無效登入");
                    localStorage.clear();
                    history.push("/");
                    return false;
                }
            } else {
                throw "無效token"
            }
        } catch (e) {
            localStorage.clear();
            alert("登入失效");
            window.location.href = "/";
            return false;
        }
        return true;
    }

    //token轉譯後存容器
    function jwtToSet(token) {
        setUSDB(token);
        setCurSemester(token.ExamNo);
        if (token.ExSchdStartDate && token.ExSchdEndDate) {
            if (moment(token.ExSchdStartDate).isValid() && moment(token.ExSchdEndDate).isValid()) {
                setCurAvaStart(moment(token.ExSchdStartDate).format("YYYY/MM/DD"));
                setCurAvaEnd(moment(token.ExSchdEndDate).format("YYYY/MM/DD"));

                setCurAvaStartTime(moment(token.ExSchdStartDate).format("HH:mm"))
                setCurAvaEndTime(moment(token.ExSchdEndDate).format("HH:mm"))
            }
        }
        setCurWishLength(token.ExamWish);
    }


    return (
        <div className="row g-0">
            <div className="col-2 bg-dark">
                <div className="vh-100">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    帳號管理
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body p-0">
                                    <Link to="/seminar/admin_account_manage" className="d-block w-100 py-3 px-5 text-decoration-none collapse-link" >建立帳號</Link>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                                    研習類別維護
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body p-0">
                                    <div className="accordion-body p-0">
                                        <Link to="/seminar/admin_category_info" className="d-block w-100 py-3 px-5 text-decoration-none collapse-link" >科別類別維護</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-10">
                <div className="container-fluid">
                    <div className="mt-4">
                        <h2>臺北市立松山家商研習心得後台管理系統</h2>
                        <div className="teacher-info-management mt-4 d-flex justify-content-between align-items-center">
                            <p>使用者: admin</p>
                        </div>
                        <div className="mt-4">
                            <h3>研習心得屬性管理</h3>
                            <div className="row row-cols-2 mb-5">
                                <div className="col">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h5 className="card-title">研習類別</h5>
                                            <div className="form-floating mb-3 w-50 mx-auto">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="inputCategory"
                                                    placeholder="(請輸入輔導知能、特殊教育等等)"
                                                    value={inputCategory}
                                                    onChange={handleInputChange}
                                                    name="inputCategory"
                                                />
                                                <label for="inputCategory">(請輸入輔導知能、特殊教育等等)</label>
                                            </div>
                                            <div className="w-100">
                                                <button className="btn btn-primary d-block w-50 mx-auto py-3" type="submit" onClick={handleSubmitCategory}>確認送出</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100">
                                        <div className="card-body d-flex flex-column justify-content-between">
                                            <div>
                                                <h5 className="card-title">研習類別列表</h5>
                                                <ul className="exam-subject-list py-4 px-0 d-flex flex-wrap w-100">
                                                    {
                                                        categoryList.map((item, index) => {
                                                            return (
                                                                <li class="me-4 mb-4" key={index}>
                                                                    <button type="button" class="btn btn-lg btn-warning position-relative">
                                                                        {item.name}
                                                                        <span
                                                                            class="position-absolute fs-7 top-0 start-100 translate-middle px-2 py-1  bg-danger border border-light rounded-circle"
                                                                            onClick={() => handleDeleteCategory(item.id)}
                                                                        >
                                                                            X
                                                                        </span>
                                                                    </button>
                                                                </li>
                                                            )
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row row-cols-2 mt-5">
                                <div className="col">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h5 className="card-title">學年度</h5>
                                            <div className="form-floating mb-3 w-50 mx-auto">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="floatingInputAcademicYear"
                                                    placeholder="(請輸入民國年)"
                                                    value={inputAcademicYear}
                                                    onChange={handleInputChange}
                                                    name="inputAcademicYear"
                                                />
                                                <label for="floatingInputAcademicYear">(請輸入110、111等)</label>
                                            </div>
                                            <div className="w-100">
                                                <button className="btn btn-primary d-block w-50 mx-auto py-3" type="submit" onClick={handleSubmitAcademicYear}>確認送出</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100">
                                        <div className="card-body d-flex flex-column justify-content-between">
                                            <div>
                                                <h5 className="card-title">學年度列表</h5>
                                                <ul className="exam-subject-list py-4 px-0 d-flex flex-wrap w-100">

                                                    {
                                                        academicYearList.map((item, index) => {
                                                            return (
                                                                <li class="me-4 mb-4" key={index}>
                                                                    <button type="button" class="btn btn-lg btn-warning position-relative">
                                                                        {item.name}
                                                                        <span
                                                                            class="position-absolute fs-7 top-0 start-100 translate-middle px-2 py-1  bg-danger border border-light rounded-circle"
                                                                            onClick={() => handleDeleteAcademicYear(item.id)}
                                                                        >
                                                                            X
                                                                        </span>
                                                                    </button>
                                                                </li>
                                                            )
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}


export default SeminarAdminCategoryInfo;
