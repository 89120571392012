import axios from 'axios';
import { getAuthToken } from './TokenUtil';

export const getHeaders = (token) => {
    return {
        'Content-Type': 'application/json;chartset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
        //'Accept-Encoding':"gzip, deflate, br",
        'Authorization': token
    }
}

//API URL
const API_URL = window.apiUrl;
//�@�뱡�p��
const baseRequest = axios.create({
    baseURL: API_URL
});
//�U����
export const downloadRequest = axios.create({
    baseURL: API_URL,
    responseType: 'blob'
});


//#region �@��api
export const apiLangSetting = data => baseRequest.get('/languageData');
export const apiAreaSetting = data => baseRequest.get('/areaData');
//#endregion


//0-0 �n�J
export const API_POST_Singin = data => baseRequest.post('/Login/SinginAdmin', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });

//1-1 ��s�Ǵ�
export const API_POST_Year_Add = data => baseRequest.post('/Year/AddYear', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });

//1-2 ��s�Ǵ� ���ɶ�
export const API_Put_Year_EditTime = data => baseRequest.put('/Year/EditTime', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//2-1 �פJ�ǥ͸��  POST /BasicInfo/ImportStudent
//2-2 �פJ�Ǯո��  POST /BasicInfo/ImportSch
//2-3 �פJ�Ǯո��  POST /BasicInfo/ImportSchSubBase
//2-4 �פJ�Ǯո��  POST /BasicInfo/ImportDeptBase
//�אּ�ޥέ�fetch

//3-1 �ץX �Z�űb���K�X
export const API_POST_Export_ClassBase = data => downloadRequest.post('/Export/ExportClassBaseInfo', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//3-2 �ץX ����g���@�ǥͦC��
export const API_POST_Export_UnWishStu = data => downloadRequest.post('/Export/ExportUnWishStu', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//3-3 �ץX �ǥͳ��W���O�@����
export const API_POST_Export_WishStuCost = data => downloadRequest.post('/Export/ExportWishStuCost', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//3-4 �ץX �ǥͲδ����W�����
export const API_POST_Export_SingUpStu = data => downloadRequest.post('/Export/ExportStuSignUpBase', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//3-5 �ץX �e���|���W����
export const API_POST_Export_Committee = data => downloadRequest.post('/Export/ExportCommittee', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });


//4-1-1 �d�� - �ǥ͸��
export const API_Get_Basic_StudInfo = data => baseRequest.get('/BasicInfo/StudInfo', { params: data, headers: getHeaders(getAuthToken()) });

//4-1-2 ��s - �ǥ͸��
export const API_Put_Edit_StudInfo = data => baseRequest.put('/BasicInfo/EditStudInfo', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//4-2-1 �d�� - �ǮզC��
export const API_Get_Basic_SchBaseList = data => baseRequest.get('/BasicInfo/SchBaseList', { params: data, headers: getHeaders(getAuthToken()) });

//4-2-2 �d�� - �Ǯըt�ҲM��
export const API_Get_Basic_SchSubList = data => baseRequest.get('/BasicInfo/SchSubBaseList', { params: data, headers: getHeaders(getAuthToken()) });

//4-3-1 �d�� - �Ҹ����s�N�X
export const API_Get_Basic_DeptInfo = data => baseRequest.get('/BasicInfo/DeptBaseInfo', { params: data, headers: getHeaders(getAuthToken()) });

//5-1-1 �ǥ� �U�Կ�� - �Ǯ�
export const API_GET_StuSchList = data => baseRequest.get('/Stud/SchList', { params: data, headers: getHeaders(getAuthToken()) });

//5-1-2 �ǥ� �U�Կ�� - �t��
export const API_GET_StuSubList = data => baseRequest.get('/Stud/SchSubList', { params: data, headers: getHeaders(getAuthToken()) });

//5-2-1 �ǥͧ��@ - ��s
export const API_POST_StuWishUpdate = data => baseRequest.post('/Stud/AddStudWish', JSON.stringify(data), { data: data, headers: getHeaders(getAuthToken()) });

//5-2-2 �ǥͧ��@ - �d��
export const API_GET_StuWish = data => baseRequest.get('/Stud/StuWishBase', { params: data, headers: getHeaders(getAuthToken()) });

//6-1-1 �Ѯv �ǥͦC��
export const API_GET_StuStuBase = data => baseRequest.get('/Stud/StuBase', { params: data, headers: getHeaders(getAuthToken()) });

//���o�Ҧ��D�w�b��
export const API_GetAll_QuestionBankAccount = data => baseRequest.get('/QuestionBankAccount/GetAllQuestionBankAccount', { params: data, headers: getHeaders(getAuthToken()) });

//�d���D�w�b��
export const API_Query_QuestionBankAccount = data => baseRequest.get('/QuestionBankAccount/QueryQuestionBankAccount', { params: data, headers: getHeaders(getAuthToken()) });

//�s�W�D�w�b��
export const API_Create_QuestionBankAccount = data => baseRequest.post('/QuestionBankAccount/CreateQuestionBankAccount', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//��s�D�w�b��
export const API_Update_QuestionBankAccount = data => baseRequest.post('/QuestionBankAccount/UpdateQuestionBankAccount', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���m�D�w�b���K�X
export const API_ResetPassword_QuestionBankAccount = data => baseRequest.post('/QuestionBankAccount/ResetQuestionBankAccountPassword', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�ק��D�w�b���K�X
export const API_ChangePassword_QuestionBankAccount = data => baseRequest.post('/QuestionBankAccount/ChangeQuestionBankAccountPassword', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�n�J�D�w�b��
export const API_Login_QuestionBankAccount = data => baseRequest.post('/QuestionBankAccount/Login', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�R���D�w�b��
export const API_Delete_QuestionBankAccount = data => baseRequest.post('/QuestionBankAccount/DeleteQuestionBankAccount', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�妸�W���D�w�b��
export const API_Batch_Create_QuestionBankAccount = (data, file) => {
    const formData = new FormData();

    formData.append('file', file);

    let url = API_URL + '/QuestionBankAccount/BatchUploadQuestionBankAccount';
    return axios.postForm(url, formData, { headers: getFormDataHeaders(getAuthToken()) })
};

export const API_Download_QuestionBankAccount_Template = data => baseRequest.post('/QuestionBankAccount/DownloadAccountTemplate', JSON.stringify(data), { headers: getHeaders(getAuthToken()), responseType: 'blob' });


//�s�W�D�w��O
export const API_Create_QuestionBankSubject = data => baseRequest.post('/ExamPaperProperties/CreateQuestionBankSubject', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�R���D�w��O
export const API_Delete_QuestionBankSubject = data => baseRequest.post('/ExamPaperProperties/DeleteQuestionBankSubject', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���o�Ҧ��D�w��O
export const API_GetAll_QuestionBankSubject = data => baseRequest.get('/ExamPaperProperties/GetAllQuestionBankSubject', { params: data, headers: getHeaders(getAuthToken()) });

//�s�W�D�w�Ҹ����O
export const API_Create_QuestionBankExamCategory = data => baseRequest.post('/ExamPaperProperties/CreateQuestionBankExamCategory', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�R���D�w�Ҹ����O
export const API_Delete_QuestionBankExamCategory = data => baseRequest.post('/ExamPaperProperties/DeleteQuestionBankExamCategory', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���o�Ҧ��D�w�Ҹ����O
export const API_GetAll_QuestionBankExamCategory = data => baseRequest.get('/ExamPaperProperties/GetAllQuestionBankExamCategory', { params: data, headers: getHeaders(getAuthToken()) });

//�s�W�D�w�Ǧ~��
export const API_Create_QuestionBankAcademicYear = data => baseRequest.post('/ExamPaperProperties/CreateQuestionBankAcademicYear', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//�R���D�w�Ǧ~��
export const API_Delete_QuestionBankAcademicYear = data => baseRequest.post('/ExamPaperProperties/DeleteQuestionBankAcademicYear', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���o�Ҧ��D�w�Ǧ~��
export const API_GetAll_QuestionBankAcademicYear = data => baseRequest.get('/ExamPaperProperties/GetAllQuestionBankAcademicYear', { params: data, headers: getHeaders(getAuthToken()) });


//�d���D�w�b��
export const API_Get_QuestionBankAccount_UserView = data => baseRequest.get('/QuestionBankAccount/GetQuestionBankAccountUserView', { params: data, headers: getHeaders(getAuthToken()) });


export const getFormDataHeaders = (token) => {
    return {
        'Authorization': token
    }
}

//�s�W�D�w
export const API_Create_QuestionBank = (data, file) => {
    const formData = new FormData();

    formData.append('questionBankAccountId', data.questionBankAccountId);
    formData.append('academicYear', data.academicYear);
    formData.append('semeste', data.semeste);
    formData.append('target', data.target);
    formData.append('grade', data.grade);
    formData.append('examCatrgory', data.examCatrgory);
    formData.append('subject', data.subject);
    formData.append('paperIllustrate', data.paperIllustrate);

    formData.append('file', file);

    let url = API_URL + '/QuestionBank/CreateQuestionBank';
    return axios.postForm(url, formData, { headers: getFormDataHeaders(getAuthToken()) })
};

//���o�Ҧ��D��
export const API_GetAll_QuestionBank = data => baseRequest.get('/QuestionBank/GetAllQuestionBank', { params: data, headers: getHeaders(getAuthToken()) });

//�D�w�˯�
export const API_Search_QuestionBank = data => baseRequest.get('/QuestionBank/SearchQuestionBank', { params: data, headers: getHeaders(getAuthToken()) });

//�U���D��
export const API_Download_Question = data => downloadRequest.post('/QuestionBank/DownloadQuestion', JSON.stringify(data), { headers: getHeaders(getAuthToken()), responseType: 'blob' });

//�R���D��
export const API_Delete_Question = data => baseRequest.post('/QuestionBank/DeleteQuestion', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//���o�Ҧ��Юv��߱b��
export const API_GetAll_TeacherTrainingAccount = data => baseRequest.get('/TeacherTrainingAccount/GetAllTeacherTrainingAccount', { params: data, headers: getHeaders(getAuthToken()) });

//�d�߱Юv��߱b��
export const API_Query_TeacherTrainingAccount = data => baseRequest.get('/TeacherTrainingAccount/QueryTeacherTrainingAccount', { params: data, headers: getHeaders(getAuthToken()) });

//�s�W�Юv��߱b��
export const API_Create_TeacherTrainingAccount = data => baseRequest.post('/TeacherTrainingAccount/CreateTeacherTrainingAccount', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//��s�Юv��߱b��
export const API_Update_TeacherTrainingAccount = data => baseRequest.post('/TeacherTrainingAccount/UpdateTeacherTrainingAccount', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//���m�Юv��߱b���K�X
export const API_ResetPassword_TeacherTrainingAccount = data => baseRequest.post('/TeacherTrainingAccount/ResetTeacherTrainingAccountPassword', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�ק�Юv��߱b���K�X
export const API_ChangePassword_TeacherTrainingAccount = data => baseRequest.post('/TeacherTrainingAccount/ChangeTeacherTrainingAccountPassword', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�n�J�Юv��߱b��
export const API_Login_TeacherTrainingAccount = data => baseRequest.post('/TeacherTrainingAccount/Login', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�R���Юv��߱b��
export const API_Delete_TeacherTrainingAccount = data => baseRequest.post('/TeacherTrainingAccount/DeleteTeacherTrainingAccount', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�妸�W�ǱЮv��߱b��
export const API_Batch_Create_TeacherTrainingAccount = (data, file) => {
    const formData = new FormData();

    formData.append('file', file);

    let url = API_URL + '/TeacherTrainingAccount/BatchUploadTeacherTrainingAccount';
    return axios.postForm(url, formData, { headers: getFormDataHeaders(getAuthToken()) })
};

//�d�߬�߸�Ʊb��
export const API_Get_TeacherTrainingAccount_UserView = data => baseRequest.get('/TeacherTrainingAccount/GetTeacherTrainingAccountUserView', { params: data, headers: getHeaders(getAuthToken()) });

//�s�W������O
export const API_Create_TeacherTrainingCategory = data => baseRequest.post('/TeacherTrainingCategory/CreateTeacherTrainingCategory', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�R��������O
export const API_Delete_TeacherTrainingCategory = data => baseRequest.post('/TeacherTrainingCategory/DeleteTeacherTrainingCategory', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���o�Ҧ�������O
export const API_GetAll_TeacherTrainingCategory = data => baseRequest.get('/TeacherTrainingCategory/GetAllTeacherTrainingCategory', { params: data, headers: getHeaders(getAuthToken()) });

//�s�W�Ǧ~��
export const API_Create_TeacherTrainingAcademicYear = data => baseRequest.post('/TeacherTrainingCategory/CreateTeacherTrainingAcademicYear', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�R���Ǧ~��
export const API_Delete_TeacherTrainingAcademicYear = data => baseRequest.post('/TeacherTrainingCategory/DeleteTeacherTrainingAcademicYear', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//���o�Ҧ��Ǧ~��
export const API_GetAll_TeacherTrainingAcademicYear = data => baseRequest.get('/TeacherTrainingCategory/GetAllTeacherTrainingAcademicYear', { params: data, headers: getHeaders(getAuthToken()) });


//�s�W��߸��
export const API_Create_TeacherTraining = (data, trainingThoughts, trainingData) => {
    const formData = new FormData();

    formData.append('teacherTrainingAccountId', data.teacherTrainingAccountId);
    formData.append('academicYear', data.academicYear);
    formData.append('teacherTrainingCategory', data.teacherTrainingCategory);
    formData.append('trainingBeginDate', data.trainingBeginDate);
    formData.append('trainingEndDate', data.trainingEndDate);
    formData.append('trainingHour', data.trainingHour);
    formData.append('paperIllustrate', data.paperIllustrate);

    formData.append('trainingThoughts', trainingThoughts);
    formData.append('trainingData', trainingData);

    let url = API_URL + '/TeacherTraining/CreateTeacherTraining';
    return axios.postForm(url, formData, { headers: getFormDataHeaders(getAuthToken()) })
};

//�d�ߩҦ���߸��
export const API_GetAll_TeacherTraining = data => baseRequest.get('/TeacherTraining/GetAllTeacherTraining', { params: data, headers: getHeaders(getAuthToken()) });

//��߸���˯�
export const API_Search_TeacherTraining = data => baseRequest.get('/TeacherTraining/SearchTeacherTraining', { params: data, headers: getHeaders(getAuthToken()) });


//�U���D��
export const API_Download_TeacherTraining = data => downloadRequest.post('/TeacherTraining/DownloadTeacherTrainingData', JSON.stringify(data), { headers: getHeaders(getAuthToken()), responseType: 'blob' });

//�R���D��
export const API_Delete_TeacherTraining = data => baseRequest.post('/TeacherTraining/DeleteTeacherTraining', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�U����߸�ƪťսd��
export const API_Download_TeacherTrainingEmptyForm = data => downloadRequest.post('/TeacherTraining/DownloadTeacherTrainingEmptyForm', JSON.stringify(data), { headers: getHeaders(getAuthToken()), responseType: 'blob' });

