import "../../js/jquery.min.js";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import {
    API_GetAll_TeacherTrainingAccount,
    API_Query_TeacherTrainingAccount,
    API_Create_TeacherTrainingAccount,
    API_ResetPassword_TeacherTrainingAccount,
    API_Delete_TeacherTrainingAccount,
    API_Batch_Create_TeacherTrainingAccount,
    API_Download_QuestionBankAccount_Template,
    API_Update_TeacherTrainingAccount
} from '../../global/constants';
import moment from 'moment';
import { checkAuthToken } from '../../global/TokenUtil';
import Modal from '../../global/Modal';
import useModal from '../../global/useModal';

function SeminarAdminAccManage() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const fileInputRef = useRef();

    const { activeModal, openModal, closeModal } = useModal();

    const [usDB, setUSDB] = useState({});

    //環境數值
    const [curSemester, setCurSemester] = useState("");//112
    const [curAvaStart, setCurAvaStart] = useState("");//2023/5/1
    const [curAvaEnd, setCurAvaEnd] = useState("");//2023/5/30
    const [curWishLength, setCurWishLength] = useState("");//6
    const [curAvaStartTime, setCurAvaStartTime] = useState("");//12:00
    const [curAvaEndTime, setCurAvaEndTime] = useState("");//12:00

    //建立帳號參數
    const [createUserName, setCreateUserName] = useState("")
    const [createUserAccount, setCreateUserAccount] = useState("")
    const [createUserIdentity, setCreateUserIdentity] = useState("")
    const [isCreated, setIsCreated] = useState(false);
    const [isReGet, setIsReGet] = useState(false);

    //查詢帳號參數
    const [searchName, setSearchName] = useState("")
    const [searchAccount, setSearchAccount] = useState("")
    const [searchIdNumber, setSearchIdNumber] = useState("")

    const [updatingId, setUpdatingId] = useState(null);
    const [updateName, setUpdateName] = useState("");
    const [updateIdNumber, setUpdateIdNumber] = useState("");

    const [dataList, setDataList] = useState([])

    const [selectedFile, setSelectedFile] = useState(null);

    function getData() {
        API_GetAll_TeacherTrainingAccount()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setDataList(data.result);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case 'userName':
                setCreateUserName(value);
                break;
            case 'userAccount':
                setCreateUserAccount(value);
                break;
            case 'userIdentity':
                setCreateUserIdentity(value);
                break;
            case 'searchName':
                setSearchName(value);
                break;
            case 'searchAccount':
                setSearchAccount(value);
                break;
            case 'searchIdNumber':
                setSearchIdNumber(value);
                break;
            case 'updateName':
                setUpdateName(value);
                break;
            case 'updateIdNumber':
                setUpdateIdNumber(value);
                break;
            default:
                break;
        }
    }

    const handleSubmitCreate = () => {
        // 在這裡處理按鈕點擊事件
        let errorStr = "";

        if (createUserName == null || createUserName == "") {
            errorStr += "姓名必填！\r\n"
        }

        if (createUserAccount == null || createUserAccount == "") {
            errorStr += "帳號必填！\r\n"
        }

        if (createUserIdentity == null || createUserIdentity == "") {
            errorStr += "身分證必填！\r\n"
        }

        if (errorStr != "") {
            alert(errorStr);
            return;
        }

        let dt = {
            name: createUserName,
            account: createUserAccount,
            identity: createUserIdentity
        }

        API_Create_TeacherTrainingAccount(dt)
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    AfterCreateHandle();
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push('/');
                }
                else if (err.response.status === 400) {
                    alert("客戶端錯誤，請檢查必填欄位！");
                }
            })
    };


    const handleSubmitSearch = () => {
        var dt = {
            name: searchName,
            account: searchAccount,
            identity: searchIdNumber
        }

        API_Query_TeacherTrainingAccount(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setDataList(data.result);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push('/');
                }
                else if (err.response.status === 400) {
                    alert("客戶端錯誤，請檢查必填欄位！");
                }
            })
    }

    const handleResetPassword = (id) => {
        var dt = {
            id: id,
        }

        API_ResetPassword_TeacherTrainingAccount(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    alert("重置成功！");
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push('/');
                }
                else if (err.response.status === 400) {
                    alert("客戶端錯誤，請檢查必填欄位！");
                }
            })
    }

    const handleDeleteAccount = (id) => {
        var dt = {
            id: id,
        }

        API_Delete_TeacherTrainingAccount(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    alert("刪除成功！");
                    setIsReGet(true);

                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    const handleCloseUpdateModal = () => {
        setUpdatingId(null);
        setUpdateName("");
        closeModal();
    }

    const handleUpdateAccount = (id, name, idNumber) => {
        setUpdatingId(id);
        setUpdateName(name);
        setUpdateIdNumber(idNumber);
        openModal("changeName")
    }

    const handleUpdateSubmit = (id, name, idNumber) => {
        if (name == null || name == "") {
            alert("姓名必填！");
        }

        if (idNumber == null || idNumber == "") {
            alert("身分證必填！");
        }

        let dt = {
            id: id,
            name: name,
            identity: idNumber
        }

        API_Update_TeacherTrainingAccount(dt)
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    handleCloseUpdateModal();
                    setIsReGet(true);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push('/');
                }
                else if (err.response.status === 400) {
                    alert("客戶端錯誤，請檢查必填欄位！");
                }
            })
    }

    function AfterCreateHandle() {
        setSearchName(createUserName);
        setSearchAccount(createUserAccount);
        setSearchIdNumber(createUserIdentity);

        setCreateUserName("");
        setCreateUserAccount("");
        setCreateUserIdentity("");

        setIsCreated(true);
    }

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file) {
                const fileType = file.type;
                const validTypes = [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ];
                if (!validTypes.includes(fileType)) {
                    alert('不支持的文件類型');

                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                    return;
                }

                setSelectedFile(file);
            }
        }
    };

    const handleSubmitBatchCreate = () => {

        if (selectedFile == null) {
            alert("請選擇檔案！")
            return;
        }

        API_Batch_Create_TeacherTrainingAccount(null, selectedFile)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    alert("上傳成功！");
                    setIsReGet(true);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push('/');

                }
                else if (err.response.status === 400) {
                    alert("客戶端錯誤，請檢查必填欄位！");
                }
            })
    }

    const templateDownloadHandle = () => {
        const FileDownload = require("js-file-download");

        API_Download_QuestionBankAccount_Template()
            .then((response) => {
                console.log(response);
                FileDownload(response.data, "AccountImportTemplate.xlsx");
            })
            .catch(err => {
                console.log(err);
                alert("無檔案可下載");
            })
    }

    function tokenHandle() {
        if (localStorage.getItem('isLogin')) {
            //查驗token
            if (jwtCheck()) {
                let usDB = JSON.parse(localStorage.getItem('UserData'));
                if (!usDB) {

                } else {
                    jwtToSet(usDB);
                }
            }
        } else {
            history.push("/")
        }
    }

    //token解析
    function jwtCheck(inputToken) {
        let qqq = localStorage.getItem("token").split(".");
        if (inputToken) {
            qqq = inputToken.split(".");
        }
        try {
            var arq = JSON.parse(decodeURIComponent(escape(window.atob(qqq[1].replace(/-/g, "+").replace(/_/g, "/")))));
            if (arq && Object.keys(arq)) {
                if (arq.type != "admin") {
                    alert("無效登入");
                    localStorage.clear();
                    history.push("/");
                    return false;
                }
            } else {
                throw "無效token"
            }
        } catch (e) {
            localStorage.clear();
            alert("登入失效");
            window.location.href = "/";
            return false;
        }
        return true;
    }

    //token轉譯後存容器
    function jwtToSet(token) {
        setUSDB(token);
        setCurSemester(token.ExamNo);
        if (token.ExSchdStartDate && token.ExSchdEndDate) {
            if (moment(token.ExSchdStartDate).isValid() && moment(token.ExSchdEndDate).isValid()) {
                setCurAvaStart(moment(token.ExSchdStartDate).format("YYYY/MM/DD"));
                setCurAvaEnd(moment(token.ExSchdEndDate).format("YYYY/MM/DD"));

                setCurAvaStartTime(moment(token.ExSchdStartDate).format("HH:mm"))
                setCurAvaEndTime(moment(token.ExSchdEndDate).format("HH:mm"))
            }
        }
        setCurWishLength(token.ExamWish);
    }

    useEffect(() => {
        tokenHandle();
        getData();
    }, []);

    useEffect(() => {
        if (isCreated == true) {
            handleSubmitSearch();
            setIsCreated(false);
        }
    }, [isCreated])

    useEffect(() => {
        if (isReGet == true) {
            getData();
            setIsReGet(false);
        }
    }, [isReGet])

    return (
        <>
            <div className="row g-0">
                <div className="col-2 bg-dark">
                    <div className="vh-100">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        帳號管理
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#collapseOne">
                                    <div className="accordion-body p-0">
                                        <Link to="/seminar/admin_account_manage" className="d-block w-100 py-3 px-5 text-decoration-none collapse-link" >建立帳號</Link>
                                    </div>
                                    <div className="accordion-body p-0">
                                        <Link to="/seminar/admin_account_manage/seach_system" className="d-block w-100 py-3 px-5 text-decoration-none collapse-link" >研習心得維護</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                                        研習類別維護
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body p-0">
                                        <div className="accordion-body p-0">
                                            <Link to="/seminar/admin_category_info" className="d-block w-100 py-3 px-5 text-decoration-none collapse-link" >科別類別維護</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-10">
                    <div className="container-fluid">
                        <div className="mt-4">
                            <h2>臺北市立松山家商研習心得後台管理系統</h2>
                            <div className="teacher-info-management mt-4 d-flex justify-content-between align-items-center">
                                <p>使用者: admin</p>
                            </div>
                            <div className="mt-4">
                                <h3>建立帳號</h3>
                                <div className="row row-cols-2">
                                    <div className="col">
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <h5 className="card-title">單次上傳</h5>
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="floatingInputName"
                                                        placeholder="王小明"
                                                        value={createUserName}
                                                        onChange={handleInputChange}
                                                        name="userName"
                                                    />
                                                    <label htmlFor="floatingInput">姓名</label>
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="floatingAccount"
                                                        placeholder="123456"
                                                        value={createUserAccount}
                                                        onChange={handleInputChange}
                                                        name="userAccount"
                                                    />
                                                    <label htmlFor="floatingAccount">帳號</label>
                                                </div>
                                                <div className="form-floating mb-5">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="floatingIdNumber"
                                                        placeholder="A123456789"
                                                        value={createUserIdentity}
                                                        onChange={handleInputChange}
                                                        name="userIdentity"
                                                    />
                                                    <label htmlFor="floatingIdNumber">身分證</label>
                                                </div>
                                                <div className="w-100">
                                                    <button className="btn btn-primary d-block w-50 mx-auto py-3" type="submit" onClick={handleSubmitCreate}>確認送出</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card h-100">
                                            <div className="card-body d-flex flex-column justify-content-between">
                                                <div>
                                                    <h5 className="card-title">批次上傳</h5>
                                                    <label htmlFor="formFile" className="form-label">請使用excel檔匯入</label>
                                                    <a href="#" style={{ marginLeft: 10 }} onClick={templateDownloadHandle}>範本下載</a>
                                                    <input className="form-control mb-5" type="file" id="formFile" ref={fileInputRef} onChange={handleFileChange} />
                                                </div>
                                                <button className="btn btn-primary w-50 d-block mx-auto p-3" type="submit" onClick={handleSubmitBatchCreate}>確認送出</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-4 mb-4"></hr>
                            <div className="mt-4">
                                <h3 className="mb-3">帳號列表</h3>
                                <div className="mb-3">
                                    <div className="d-flex align-items-end">
                                        <div className="me-4">
                                            <label htmlFor="searchName" className="form-label">姓名</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="searchName"
                                                placeholder="請輸入教師姓名"
                                                value={searchName}
                                                onChange={handleInputChange}
                                                name="searchName"
                                            />
                                        </div>
                                        <div className="me-4">
                                            <label htmlFor="searchAccount" className="form-label">帳號</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="searchAccount"
                                                placeholder="xxx@gmail.com"
                                                value={searchAccount}
                                                onChange={handleInputChange}
                                                name="searchAccount"
                                            />
                                        </div>
                                        <div className="me-4">
                                            <label htmlFor="searchIdNumber" className="form-label">身分證</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="searchIdNumber"
                                                placeholder="A123456789"
                                                value={searchIdNumber}
                                                onChange={handleInputChange}
                                                name="searchIdNumber"
                                            />
                                        </div>
                                        <div>
                                            <button className="btn btn-outline-primary w-100" type="submit" onClick={handleSubmitSearch}>搜尋</button>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-striped table-hover table-bordered table-bordered-dark">
                                    <thead className="table-dark">
                                        <tr>
                                            <th scope="col">編號</th>
                                            <th scope="col">姓名</th>
                                            <th scope="col">帳號</th>
                                            <th scope="col">身分證</th>
                                            <th scope="col">功能</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataList.map((item, index) => (
                                            <tr key={index}>
                                                <th scope="row">{item.id}</th>
                                                <td>{item.name}</td>
                                                <td>{item.account}</td>
                                                <td>{item.identity}</td>
                                                <td>
                                                    <div className="row g-0">
                                                        <div className="col-2">
                                                            <button className="btn btn-primary" onClick={() => handleUpdateAccount(item.id, item.name, item.identity)}>編輯</button>
                                                        </div>
                                                        <div className="col-2">
                                                            <button className="btn btn-danger" onClick={() => handleDeleteAccount(item.id)}>刪除</button>
                                                        </div>
                                                        <div className="col-3">
                                                            <button className="btn btn-secondary" onClick={() => handleResetPassword(item.id)}>重置密碼</button>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal isActive={activeModal === 'changeName'} labelledby={"changeName"}>
                <div className="modal-header align-items-start py-0 border-0 mx-0">
                    <h3>編輯教師資料</h3>
                </div>
                <div className="modal-body pt-0">
                    <div className="">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="updateName"
                                placeholder="請輸入教師姓名"
                                value={updateName}
                                onChange={handleInputChange}
                                name="updateName"
                            />
                            <label htmlFor="updateName">姓名</label>
                        </div>

                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="updateIdNumber"
                                placeholder="請輸入身分證"
                                value={updateIdNumber}
                                onChange={handleInputChange}
                                name="updateIdNumber"
                            />
                            <label htmlFor="updateIdNumber">身分證</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={handleCloseUpdateModal}>關閉</button>
                    <button type="button" className="btn btn-primary" onClick={() => handleUpdateSubmit(updatingId, updateName, updateIdNumber)}>送出</button>
                </div>
            </Modal>
        </>

    );
}


export default SeminarAdminAccManage;
