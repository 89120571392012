import "../../js/jquery.min.js";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_ChangePassword_QuestionBankAccount,
    API_Get_QuestionBankAccount_UserView,
    API_GetAll_QuestionBankSubject,
    API_GetAll_QuestionBankExamCategory,
    API_GetAll_QuestionBankAcademicYear,
    API_Create_QuestionBank,
    API_GetAll_QuestionBank,
    API_Download_Question,
    API_Delete_Question
} from '../../global/constants';
import Modal from '../../global/Modal';
import useModal from '../../global/useModal';

function QBankTeacher() {
    const { activeModal, openModal, closeModal } = useModal();

    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const fileInputRef = useRef();

    const [isReGet, setIsReGet] = useState(false);
    const [questionBankAccountId, setquestionBankAccountId] = useState(null);
    const [questionBankAccount, setQuestionBankAccount] = useState(null);

    const [isResettingPwd, setIsResettingPwd] = useState(false);

    const [inputOldPassword, setInputOldPassword] = useState("");
    const [inputNewPassword, setInputNewPassword] = useState("");
    const [inputNewPasswordCheck, setInputNewPasswordCheck] = useState("");

    const [academicYears, setAcademicYears] = useState([]);
    const [semestes, setSemestes] = useState(["上", "下"]);
    const [targets, setTargets] = useState(["普通科", "職業類"]);
    const [grades, setGrades] = useState(["1", "2", "3"]);
    const [examCatrgories, setExamCatrgories] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const [academicYearSelected, setAcademicYearSelected] = useState(null);
    const [semesteSelected, setSemesteSelected] = useState("上");
    const [targetSelected, setTargetSelected] = useState("普通科");
    const [gradeSelected, setGradeSelected] = useState("1");
    const [examCatrgorySelected, setExamCatrgorySelected] = useState(null);
    const [subjectSelected, setSubjectSelected] = useState(null);

    const [testPaperIllustrate, setTestPaperIllustrate] = useState("");

    const [selectedFile, setSelectedFile] = useState(null);

    const [dataList, setDataList] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        console.log(name, value);

        switch (name) {
            case 'inputOldPassword':
                setInputOldPassword(value);
                break;
            case 'inputNewPassword':
                setInputNewPassword(value);
                break;
            case 'inputNewPasswordCheck':
                setInputNewPasswordCheck(value);
                break;
            case 'testPaperIllustrate':
                setTestPaperIllustrate(value);
                break;
            case 'academicYearSelected':
                setAcademicYearSelected(value);
                break;
            case 'semesteSelected':
                setSemesteSelected(value);
                break;
            case 'targetSelected':
                setTargetSelected(value);
                break;
            case 'gradeSelected':
                setGradeSelected(value);
                break;
            case 'examCatrgorySelected':
                setExamCatrgorySelected(value);
                break;
            case 'subjectSelected':
                setSubjectSelected(value);
                break;
            default:
                break;
        }
    }

    const handleQuestionSubmit = () => {
        var dt = {
            "questionBankAccountId": questionBankAccountId,
            "academicYear": academicYearSelected,
            "semeste": semesteSelected,
            "target": targetSelected,
            "grade": gradeSelected,
            "examCatrgory": examCatrgorySelected,
            "subject": subjectSelected,
            "paperIllustrate": testPaperIllustrate,
        };

        var file = selectedFile;

        console.log("預覽送出資料", [dt, file]);

        if (testPaperIllustrate == null || testPaperIllustrate == "") {
            alert("試卷說明必填！");
            return;
        }

        API_Create_QuestionBank(dt, file)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    alert("新增成功！");
                    setIsReGet(true);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    // localStorage.clear();
                    // history.push('/');
                }
            })
    }

    const handleChangePassword = () => {
        setIsResettingPwd(true)
        openModal("changePassword");
    }

    const handleChangePasswordSumit = () => {
        if (inputNewPassword != inputNewPasswordCheck) {
            alert("新密碼和密碼確認不一致，請重新確認");
            return;
        }
        console.log("重設資料Log", [inputOldPassword, inputNewPassword, inputNewPasswordCheck]);

        var dt = {
            id: questionBankAccountId,
            oldPassword: inputOldPassword,
            newPassword: inputNewPassword
        }

        API_ChangePassword_QuestionBankAccount(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    alert("密碼更改成功！");
                    setInputOldPassword("");
                    setInputNewPassword("");
                    setInputNewPasswordCheck("");
                    setIsResettingPwd(false);
                    closeModal();
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    // localStorage.clear();
                    // history.push('/');
                }
            })

    }

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file) {
                const fileType = file.type;
                const validTypes = [
                    'application/pdf'
                ];
                if (!validTypes.includes(fileType)) {
                    alert('不支持的文件類型');

                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                    return;
                }

                setSelectedFile(file);
            }
        }
    };

    const handleQuestionDownload = (filename, sourceName) => {
        const FileDownload = require("js-file-download");

        API_Download_Question({
            "fileName": filename,
            "sourceName": sourceName
        })
            .then((response) => {
                console.log(response);
                FileDownload(response.data, sourceName);
            })
            .catch(err => {
                console.log(err);
                alert("無檔案可下載");
            })
    }

    const handleDeleteQuestion = (id) => {
        console.log(id);
        var dt = {
            id: id,
        }

        API_Delete_Question(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setIsReGet(true);

                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    const getQuetionBankAcccount = (id) => {
        var dt = {
            id: id
        }

        API_Get_QuestionBankAccount_UserView(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    let entity = data.result;
                    if (entity == null) {
                        alert("帳號不存在")
                    }
                    else {
                        setQuestionBankAccount(data.result);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    // localStorage.clear();
                    // history.push('/');
                }
            })

    }

    function getSubject() {
        API_GetAll_QuestionBankSubject()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setSubjects(data.result);
                        setSubjectSelected(data.result[0].name);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getExamCategory() {
        API_GetAll_QuestionBankExamCategory()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setExamCatrgories(data.result);
                        setExamCatrgorySelected(data.result[0].name);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getAcademicYear() {
        API_GetAll_QuestionBankAcademicYear()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setAcademicYears(data.result);
                        setAcademicYearSelected(data.result[0].name);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getData(id) {

        let dt = {
            accountId: id
        }

        API_GetAll_QuestionBank(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setDataList(data.result);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    // localStorage.clear();
                    // history.push('/');
                }
            })
    }

    const tokenHandle = () => {
        if (localStorage.getItem("isLogin") != "1") {
            alert("登入已失效")
            localStorage.clear();
            return window.location.href = "/";
        }
        //查驗token
        let tokenSplit = localStorage.getItem("token").split(".");
        try {
            var arq = JSON.parse(decodeURIComponent(escape(window.atob(tokenSplit[1].replace(/-/g, "+").replace(/_/g, "/")))));
            if (arq && Object.keys(arq)) {
                if (arq.type == "questionBankAccount") {
                    setquestionBankAccountId(arq.id);
                }
                else {
                    localStorage.clear();
                    alert("無效登入");
                    return history.push("/");
                }
            } else {
                throw "無效token"
            }
        } catch (e) {
            localStorage.clear();
            alert("登入失效");
            return window.location.href = "/";
        }
    }

    const initPageProp = () => {
        getSubject();
        getExamCategory();
        getAcademicYear();
    }

    useEffect(() => {
        tokenHandle();
        initPageProp();
    }, []);

    useEffect(() => {
        if (questionBankAccountId != null) {
            getQuetionBankAcccount(questionBankAccountId);
            getData(questionBankAccountId);
        }
    }, [questionBankAccountId])

    useEffect(() => {
        if (questionBankAccount != null && isResettingPwd == false) {
            if (questionBankAccount.isNeedResetPassword == true) {
                ;
                alert("首次登入或密碼已被重置，請重設密碼！");
                handleChangePassword();
            }
        }
    }, [questionBankAccount])

    useEffect(() => {
        if (isReGet == true) {
            getData(questionBankAccountId);
            setIsReGet(false);
        }
    }, [isReGet])

    return (
        <>
            <div className="container mt-4">
                <h2>臺北市立松山家商題庫上傳系統</h2>
                <div className="teacher-info-management mt-4 d-flex justify-content-between align-items-center">
                    <p>出題教師: {questionBankAccount == null ? "" : questionBankAccount.name}</p>
                    <div className="d-flex">
                        {/* <button className="btn btn-outline-primary">題庫檢索系統</button> */}
                        <button className="btn btn-outline-danger ms-4" onClick={handleChangePassword}>更改密碼</button>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="row row-cols-3">
                        <div className="col">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-8">
                                            <h5 className="card-title">Step1.試卷基本設定</h5>
                                        </div>
                                        <div className="col" style={{ textAlign: "right", color: "red" }}>(必填)</div>
                                    </div>
                                    <select className="form-select mb-2" aria-label="Default select example" value={academicYearSelected} onChange={handleInputChange} name={"academicYearSelected"}>
                                        <option defaultValue="" disabled>學年度</option>
                                        {academicYears.map((item, index) => <option value={item.name}>{item.name}</option>)}
                                    </select>
                                    <select className="form-select mb-2" aria-label="Default select example" value={semesteSelected} onChange={handleInputChange} name={"semesteSelected"}>
                                        <option defaultValue="" disabled>學期</option>
                                        {semestes.map((item, index) => <option value={item}>{item}</option>)}
                                    </select>
                                    <select className="form-select mb-2" aria-label="Default select example" value={targetSelected} onChange={handleInputChange} name={"targetSelected"}>
                                        <option defaultValue="" disabled>對象</option>
                                        {targets.map((item, index) => <option value={item}>{item}</option>)}
                                    </select>
                                    <select className="form-select mb-2" aria-label="Default select example" value={gradeSelected} onChange={handleInputChange} name={"gradeSelected"}>
                                        <option defaultValue="" disabled>年級</option>
                                        {grades.map((item, index) => <option value={item}>{item}</option>)}
                                    </select>
                                    <select className="form-select mb-2" aria-label="Default select example" value={examCatrgorySelected} onChange={handleInputChange} name={"examCatrgorySelected"}>
                                        <option defaultValue="" disabled>考試類別</option>
                                        {examCatrgories.map((item, index) => <option value={item.name}>{item.name}</option>)}
                                    </select>
                                    <select className="form-select mb-2" aria-label="Default select example" value={subjectSelected} onChange={handleInputChange} name={"subjectSelected"}>
                                        <option defaultValue="" disabled>科別</option>
                                        {subjects.map((item, index) => <option value={item.name}>{item.name}</option>)}

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5 className="card-title">Step2.試卷說明</h5>
                                    <label htmlFor="testPaperIllustrate" className="form-label">(科目、版本、範圍、內容)</label>
                                    <textarea
                                        placeholder="例如:數學,啟芳,第三冊,1-1~1-4,直線方程式"
                                        className="form-control"
                                        id="testPaperIllustrate"
                                        rows="4"
                                        value={testPaperIllustrate}
                                        onChange={handleInputChange}
                                        name="testPaperIllustrate"
                                    >
                                    </textarea>
                                    <div style={{ textAlign: "right", color: "red" }}>(必填)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5 className="card-title">Step3.上傳題目解答+確認送出</h5>
                                    <div style={{ textAlign: "right", color: "red" }}>(檔案限5MB、限PDF檔)</div>

                                    <label htmlFor="formFile" className="form-label">考試題目+答案</label>
                                    <input className="form-control mb-5" type="file" id="formFile" ref={fileInputRef} onChange={handleFileChange} />
                                </div>
                                <div className="card-footer border-0 bg-white">
                                    <button className="btn btn-primary w-100 p-3" type="submit" onClick={handleQuestionSubmit}>確認送出</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div className="mt-4">
                    <p className="mb-3">{questionBankAccount == null ? "" : questionBankAccount.name}老師，你已上傳的題目如下:</p>
                    <table className="table table-striped table-hover table-bordered table-bordered-dark">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col">編號</th>
                                <th scope="col">學年度</th>
                                <th scope="col">學期</th>
                                <th scope="col">對象</th>
                                <th scope="col">年級</th>
                                <th scope="col">考試類別</th>
                                <th scope="col">科別</th>
                                <th scope="col">試題下載</th>
                                <th scope="col">試題範圍說明</th>
                                <th scope="col">刪除</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.academicYear}</td>
                                    <td>{item.semeste}</td>
                                    <td>{item.target}</td>
                                    <td>{item.grade}</td>
                                    <td>{item.examCatrgory}</td>
                                    <td>{item.subject}</td>
                                    <td><a href="#" onClick={() => handleQuestionDownload(item.questionFilePath, item.questionFileName)}>題目</a></td>
                                    <td>{item.paperIllustrate}</td>
                                    <td><a href="#" onClick={() => handleDeleteQuestion(item.id)}>X</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal isActive={activeModal === 'changePassword'} labelledby={"changePassword"}>
                <div className="modal-header align-items-start py-0 border-0 mx-0">
                    <h3>更改密碼</h3>
                </div>
                <div className="modal-body pt-0">
                    <div className="">
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                className="form-control"
                                id="inputOldPassword"
                                placeholder="123456"
                                value={inputOldPassword}
                                onChange={handleInputChange}
                                name="inputOldPassword"
                            />
                            <label htmlFor="inputOldPassword">原密碼</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                className="form-control"
                                id="inputNewPassword"
                                placeholder="123456"
                                value={inputNewPassword}
                                onChange={handleInputChange}
                                name="inputNewPassword"
                            />
                            <label htmlFor="inputNewPassword">新密碼</label>
                        </div>
                        <div className="form-floating mb-5">
                            <input
                                type="password"
                                className="form-control"
                                id="inputNewPasswordCheck"
                                placeholder="123456"
                                value={inputNewPasswordCheck}
                                onChange={handleInputChange}
                                name="inputNewPasswordCheck"
                            />
                            <label htmlFor="inputNewPasswordCheck">密碼確認</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={closeModal}>關閉</button>
                    <button type="button" className="btn btn-primary" onClick={handleChangePasswordSumit}>送出</button>
                </div>
            </Modal>
        </>
    );
}


export default QBankTeacher;
