import "../../js/jquery.min.js";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import {
    API_GetAll_QuestionBankSubject,
    API_GetAll_QuestionBankExamCategory,
    API_GetAll_QuestionBankAcademicYear,
    API_Search_QuestionBank,
    API_Download_Question
} from '../../global/constants.js';
import moment from 'moment';
import { checkAuthToken } from '../../global/TokenUtil.js';

function QBankSearchSystem() {
    const history = useHistory();

    const [teacher, setTeacher] = useState("");
    const [academicYears, setAcademicYears] = useState([{ id: null, name: "全部" }]);
    const [semestes, setSemestes] = useState(["全部", "上", "下"]);
    const [targets, setTargets] = useState(["全部", "普通科", "職業類"]);
    const [grades, setGrades] = useState(["全部", "1", "2", "3"]);
    const [examCatrgories, setExamCatrgories] = useState([{ id: null, name: "全部" }]);
    const [subjects, setSubjects] = useState([{ id: null, name: "全部" }]);

    const [academicYearSelected, setAcademicYearSelected] = useState(null);
    const [semesteSelected, setSemesteSelected] = useState("全部");
    const [targetSelected, setTargetSelected] = useState("全部");
    const [gradeSelected, setGradeSelected] = useState("全部");
    const [examCatrgorySelected, setExamCatrgorySelected] = useState(null);
    const [subjectSelected, setSubjectSelected] = useState(null);

    const [dataList, setDataList] = useState([]);

    function getSubject() {
        API_GetAll_QuestionBankSubject()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setSubjects([...subjects, ...data.result]);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getExamCategory() {
        API_GetAll_QuestionBankExamCategory()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setExamCatrgories([...examCatrgories, ...data.result]);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getAcademicYear() {
        API_GetAll_QuestionBankAcademicYear()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setAcademicYears([...academicYears, ...data.result]);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case 'teacher':
                setTeacher(value);
                break;
            case 'academicYearSelected':
                setAcademicYearSelected(value);
                break;
            case 'semesteSelected':
                setSemesteSelected(value);
                break;
            case 'targetSelected':
                setTargetSelected(value);
                break;
            case 'gradeSelected':
                setGradeSelected(value);
                break;
            case 'examCatrgorySelected':
                setExamCatrgorySelected(value);
                break;
            case 'subjectSelected':
                setSubjectSelected(value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = () => {
        var dt = {
            teacherName: teacher,
            academicYear: academicYearSelected,
            semeste: semesteSelected,
            target: targetSelected,
            grade: gradeSelected,
            examCatrgory: examCatrgorySelected,
            subject: subjectSelected
        }

        API_Search_QuestionBank(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setDataList(data.result);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.clear();
                    history.push('/');
                }
                else if (err.response.status === 400) {
                    alert("客戶端錯誤，請檢查必填欄位！");
                }
            })
    }

    const handleQuestionDownload = (filename, sourceName) => {
        const FileDownload = require("js-file-download");

        API_Download_Question({
            "fileName": filename,
            "sourceName": sourceName
        })
            .then((response) => {
                console.log(response);
                FileDownload(response.data, sourceName);
            })
            .catch(err => {
                console.log(err);
                alert("無檔案可下載");
            })
    }

    const initPageProp = () => {
        getSubject();
        getExamCategory();
        getAcademicYear();
    }

    useEffect(() => {
        initPageProp();
    }, []);


    useEffect(() => {
        console.log(dataList);
    }, [dataList])

    return (
        <>
            <div className="container mt-4">
                <h2>臺北市立松山家商題庫檢索系統</h2>
                <div className="mt-4">
                    <div className="row">
                        <div className="col">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div class="row g-3 mb-3">
                                        <div className="col-sm-6 form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="teacher"
                                                placeholder="出題老師"
                                                value={teacher}
                                                onChange={handleInputChange}
                                                name="teacher"
                                            />
                                            <label for="teacher">出題老師</label>
                                        </div>

                                        <div className="col form-floating">
                                            <select className="form-select mb-2" aria-label="Default select example" id={"academicYear"} value={academicYearSelected} onChange={handleInputChange} name={"academicYearSelected"}>
                                                <option defaultValue="" disabled>學年度</option>
                                                {academicYears.map((item, index) => <option value={item.name}>{item.name}</option>)}
                                            </select>

                                            <label for="academicYear">學年度</label>
                                        </div>
                                        <div className="col form-floating">
                                            <select className="form-select mb-2" aria-label="Default select example" id={"semeste"} value={semesteSelected} onChange={handleInputChange} name={"semesteSelected"}>
                                                <option defaultValue="" disabled>學期</option>
                                                {semestes.map((item, index) => <option value={item}>{item}</option>)}
                                            </select>

                                            <label for="semeste">學期</label>
                                        </div>
                                    </div>

                                    <div class="row g-3 mb-3">
                                        <div className="col form-floating">
                                            <select className="form-select mb-2" aria-label="Default select example" id={"target"} value={targetSelected} onChange={handleInputChange} name={"targetSelected"}>
                                                <option defaultValue="" disabled>對象</option>
                                                {targets.map((item, index) => <option value={item}>{item}</option>)}
                                            </select>

                                            <label for="target">對象</label>
                                        </div>

                                        <div className="col form-floating">
                                            <select className="form-select mb-2" aria-label="Default select example" id={"grade"} value={gradeSelected} onChange={handleInputChange} name={"gradeSelected"}>
                                                <option defaultValue="" disabled>年級</option>
                                                {grades.map((item, index) => <option value={item}>{item}</option>)}
                                            </select>

                                            <label for="grade">年級</label>
                                        </div>

                                        <div className="col form-floating">
                                            <select className="form-select mb-2" aria-label="Default select example" id={"examCatrgory"} value={examCatrgorySelected} onChange={handleInputChange} name={"examCatrgorySelected"}>
                                                <option defaultValue="" disabled>考試類別</option>
                                                {examCatrgories.map((item, index) => <option value={item.name}>{item.name}</option>)}
                                            </select>

                                            <label for="examCatrgory">考試類別</label>
                                        </div>

                                        <div className="col form-floating">
                                            <select className="form-select mb-2" aria-label="Default select example" id={"subject"} value={subjectSelected} onChange={handleInputChange} name={"subjectSelected"}>
                                                <option defaultValue="" disabled>科別</option>
                                                {subjects.map((item, index) => <option value={item.name}>{item.name}</option>)}
                                            </select>

                                            <label for="subject">科別</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-footer border-0 bg-white d-md-flex justify-content-md-end">
                                    <button className="btn btn-primary p-3" type="submit" onClick={handleSubmit}>開始搜尋試題</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div className="mt-4">
                    <p className="mb-3">篩選結果</p>
                    <table className="table table-striped table-hover table-bordered table-bordered-dark">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col">編號</th>
                                <th scope="col">學年度</th>
                                <th scope="col">學期</th>
                                <th scope="col">對象</th>
                                <th scope="col">年級</th>
                                <th scope="col">考試類別</th>
                                <th scope="col">科別</th>
                                <th scope="col">試題下載</th>
                                <th scope="col">出題老師</th>
                                <th scope="col">試題範圍說明</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.academicYear}</td>
                                    <td>{item.semeste}</td>
                                    <td>{item.target}</td>
                                    <td>{item.grade}</td>
                                    <td>{item.examCatrgory}</td>
                                    <td>{item.subject}</td>
                                    <td><a href="#" onClick={() => handleQuestionDownload(item.questionFilePath, item.questionFileName)}>題目</a></td>
                                    <td>{item.teacherName}</td>
                                    <td>{item.paperIllustrate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}


export default QBankSearchSystem;
