import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
//import { checkAuthToken } from "../../global/TokenUtil";

function Header() {
    const history = useHistory();
    const [isLogin, setIsLogin] = useState(false);
    const path = window.location.pathname;

    useEffect(() => {
        setInterval(function () {
            if (localStorage.getItem("isLogin") == "1") {
                setIsLogin(true);
            } else {
                setIsLogin(false);
            }
        }, 200)        
    }, []);

    function logout() {
        localStorage.clear();
        console.log(history);
        try {
            history.push("/");
        } catch (e) {
            window.location.href = "/";
        }
    }

    return (
        <header className="fixed-top">
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">松山家商</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item">
                                    <Link className="nav-link " aria-current="page"  to="/">Home</Link>
                                </li>
                            </ul>
                            <form className="d-flex">
                                {
                                    isLogin ?
                                        <button className="btn btn-outline-danger" onClick={logout} type="button">登出</button>
                                        : null
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );


}
export default Header;