import "../js/jquery.min.js";
import "../css/style.css"
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_POST_Singin } from '../global/constants';
import { checkAuthToken } from '../global/TokenUtil';

function home() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const [accNo, setAccNo] = useState("");
    const [accPassword, setAccPassword] = useState("");

    useEffect(() => {

    }, []);

    //登入
    function login() {
        if (!accNo) { return alert("帳號不能為空") }
        if (!accPassword) { return alert("密碼不能為空") }

        API_POST_Singin({
            "acntNo": accNo,
            "acntPwd": accPassword
        }).then((response) => response.data)
            .then((data) => {

                checkAuthToken(data);

                console.log(data);
                if (data.code == "0000") {
               
                    //var UserData = data.result[0];
                    //localStorage.setItem('UserData', JSON.stringify(UserData));
                    //localStorage.setItem('isLogin', "1");

                    console.log(data.result[0]);

                    //jwt解析
                    if (data.token.token) {
                        let qqq = data.token.token.split(".");
                        try {
                            var arq = JSON.parse(decodeURIComponent(escape(window.atob(qqq[1].replace(/-/g, "+").replace(/_/g, "/")))));
                            if (arq && Object.keys(arq)) {
                                if (arq.type == "admin") {
                                    var UserData = data.result[0];
                                    console.log(UserData);
                                    if (UserData) {
                                        localStorage.setItem('UserData', JSON.stringify(UserData));
                                    }
                                    localStorage.setItem('isLogin', "1");
                                    alert("登入成功");
                                    return history.push("/volunteer/back_Office");
                                } else if (arq.type == "teacher") {
                                    var UserData = data.result;
                                    console.log(data.result)
                                    localStorage.setItem('UserData', JSON.stringify(UserData));
                                    localStorage.setItem('isLogin', "1");
                                    alert("登入成功");
                                    return history.push("/volunteer/teacher_Office");
                                } else if (arq.type == "student") {
                                    var UserData = data.result;
                                    console.log(data.result)
                                    localStorage.setItem('UserData', JSON.stringify(UserData));
                                    localStorage.setItem('isLogin', "1");
                                    alert("登入成功");
                                    return history.push("/volunteer/student_Office");
                                } 
                            }
                            localStorage.clear();
                        } catch (e) {
                            console.log(e);
                            localStorage.clear();
                            alert("登入驗證失敗，請確認網路品質")
                        }
                    } else {
                        localStorage.clear();
                    }
                }
                else {
                    alert(data.message);
                    localStorage.clear();
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //按下enter登入
    function goEnter(e) {
        if (e.key === "Enter") {
            if (accNo && accPassword) {
                login();
            }
        }
    }

    return (
        <>
            <div className="container overflow-auto position-relative" style={{ height: "100vh", weight: "100vw", paddingTop: "5rem", paddingBottom: "3rem" }} >
                <div className="position-relative" style={{ width: "-webkit-fill-available", height: "-webkit-fill-available", overflowY: "auto", overflowX: "hidden", minHeight: "calc(100vh - 8rem)" }}>

                    <div className="position-absolute text-center sd-center" style={{
                        margin: "0 auto", left: "0", right: "0"
                    }} >

                        <div className="mx-auto my-0" style={{ width: "450px", width: "fit-content" }} >
                            <div className="p-3 border rounded bg-secondary-subtle border-secondary shadow">
                                <h1 className="fw-bold h4">
                                    登入系統
                                </h1>
                                <div className="my-3">
                                    <label className="form-label">
                                        帳號：
                                        <input className="form-control" type="text" maxLength="20" placeholder="帳號" value={accNo} onChange={(e) => setAccNo(e.target.value)} onKeyPress={goEnter} />
                                    </label>
                                </div>
                                <div className="my-3">
                                    <label className="form-label">
                                        密碼：
                                        <input className="form-control" type="password" maxLength="20" placeholder="密碼" value={accPassword} onChange={(e) => setAccPassword(e.target.value)} onKeyPress={goEnter} />
                                    </label>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={login} >登入</button>
                                </div>
                                <div className="px-3 pt-4 text-left">
                                    學生：預設帳號為學號；密碼為身分證末5碼。<br />
                                    老師：請向管理員取得班級查閱帳號
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default home;
