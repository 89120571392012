import { useState, useCallback } from 'react';

export const useModal = () => {
    const [activeModal, setActiveModal] = useState(null);

    const openModal = useCallback((modalName) => {
        setActiveModal(modalName);
    }, []);

    const closeModal = useCallback(() => {
        setActiveModal(null);
    }, []);

    return { activeModal, openModal, closeModal };
};

export default useModal;
