//#region �Τ�token
const TOKEN_NAME = 'token'
export const setAuthToken = (token) => {
    localStorage.setItem(TOKEN_NAME, token)
}
export const getAuthToken = () => {
    var token = localStorage.getItem(TOKEN_NAME)
    try {
        if (token != null) {
            return token
        } else {
            return 'xxx'
        }
    } catch {
        return 'xxx';
    }
    
}

export const removeAuthToken = () => {
    localStorage.removeItem(TOKEN_NAME)
}
export const checkAuthToken = (data) => {

    if (data !== undefined) {
        if (data.code == "1001") {
            localStorage.clear();
            return false;
        } else {
            if (data.token && data.token.token) {
                setAuthToken(data.token.token);
                return true;
            } else {
                return false;
            }
        }
    }
}
//#endregion

//#region �ȮɥΤ�token
const TEMP_TOKEN_NAME = 'tempToken'
export const setTempToken = (token) => {
    localStorage.setItem(TEMP_TOKEN_NAME, token)
}
export const getTempToken = () => {
    var token = localStorage.getItem(TEMP_TOKEN_NAME)
    if (token != null) {
        return token
    } else {
        return null
    }
}

export const removeTempToken = () => {
    localStorage.removeItem(TEMP_TOKEN_NAME)
}
//#endregion