import "../js/jquery.min.js";
import "../css/style.css"
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_POST_Singin } from '../global/constants';
import { checkAuthToken } from '../global/TokenUtil';

function Main() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const [accNo, setAccNo] = useState("");
    const [accPassword, setAccPassword] = useState("");

    useEffect(() => {

    }, []);

    //登入
    function login() {
        if (!accNo) { return alert("帳號不能為空") }
        if (!accPassword) { return alert("密碼不能為空") }

        API_POST_Singin({
            "acntNo": accNo,
            "acntPwd": accPassword
        }).then((response) => response.data)
            .then((data) => {

                checkAuthToken(data);

                console.log(data);
                if (data.code == "0000") {
               
                    //var UserData = data.result[0];
                    //localStorage.setItem('UserData', JSON.stringify(UserData));
                    //localStorage.setItem('isLogin', "1");

                    console.log(data.result[0]);

                    //jwt解析
                    if (data.token.token) {
                        let qqq = data.token.token.split(".");
                        try {
                            var arq = JSON.parse(decodeURIComponent(escape(window.atob(qqq[1].replace(/-/g, "+").replace(/_/g, "/")))));
                            if (arq && Object.keys(arq)) {
                                if (arq.type == "admin") {
                                    var UserData = data.result[0];
                                    console.log(UserData);
                                    if (UserData) {
                                        localStorage.setItem('UserData', JSON.stringify(UserData));
                                    }
                                    localStorage.setItem('isLogin', "1");
                                    alert("登入成功");
                                    return history.push("/volunteer/back_Office");
                                } else if (arq.type == "teacher") {
                                    var UserData = data.result;
                                    console.log(data.result)
                                    localStorage.setItem('UserData', JSON.stringify(UserData));
                                    localStorage.setItem('isLogin', "1");
                                    alert("登入成功");
                                    return history.push("/volunteer/teacher_Office");
                                } else if (arq.type == "student") {
                                    var UserData = data.result;
                                    console.log(data.result)
                                    localStorage.setItem('UserData', JSON.stringify(UserData));
                                    localStorage.setItem('isLogin', "1");
                                    alert("登入成功");
                                    return history.push("/volunteer/student_Office");
                                } 
                            }
                            localStorage.clear();
                        } catch (e) {
                            console.log(e);
                            localStorage.clear();
                            alert("登入驗證失敗，請確認網路品質")
                        }
                    } else {
                        localStorage.clear();
                    }
                }
                else {
                    alert(data.message);
                    localStorage.clear();
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //按下enter登入
    function goEnter(e) {
        if (e.key === "Enter") {
            if (accNo && accPassword) {
                login();
            }
        }
    }

    return (
        <>
          <div class="container"> 
                        <div className="main-bg">
                            <ul className="d-flex flex-column">
                                <li class="mb-4">
                                    <Link className="btn main-btn w-50 p-3 fs-4" to="/volunteer">學生志願選填</Link>
                                </li>
                                <li class="mb-4">
                                    <Link className="btn main-btn w-50 p-3 fs-4" to="/question_bank/signin">題庫</Link>
                                </li>
                                <li class="mb-4">
                                    <Link className="btn main-btn w-50 p-3 fs-4" to="/seminar/signin">教師研習</Link>
                                </li>
                                {/*<li class="mb-4">*/}
                                {/*    <Link className="btn main-btn w-50 p-3 fs-4" to="/"></Link>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
        </>
    );
}


export default Main;
