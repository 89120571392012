import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//import { checkAuthToken } from "../../global/TokenUtil";

function Footer() {
    var history = useHistory();
    //const path = window.location.pathname;

    useEffect(() => {

    }, []);

    return (
        <div className="d-none d-md-block bg-light" style={{ fontSize: "0.8rem" }} >
            <span className="d-none">
                <div align='center'>
                    系統資訊：
                    <a href="https://www.ssvs.tp.edu.tw/" target="_blank">松山家商四技二專甄選入學申請志願報填系統</a>
                    電話：(02)2726-1118。&nbsp;
                    傳真電話：(02)2728-1475 &nbsp;
                    {/*報名諮詢時間：09:30~16:30*/}
                </div>
                <div align='center'>
                    系統：
                    松盟科技股份有限公司
                    電話：(04)23152500 轉 601、810
                    系統諮詢時間：(08:30~12:00)(13:30~17:30)
                    客服信箱：edu-service@summit-edu.com.tw
                </div>
            </span>
            <div className="footer text-center">
                <span className="d-block py-2 border-top">
                        Google瀏覽器（Chrome）、
                        Firefox瀏覽器（Firefox）、
                        IE11瀏覽器（Internet Explorer 11），
                        以達到最佳的顯示效果
                </span>
            </div>
        </div>
    );

}
export default Footer;