import "../../js/jquery.min.js";
import "../../css/style.css";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, Switch, Route, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_POST_Year_Add,
    API_Put_Year_EditTime,
    API_Get_Basic_StudInfo,
    API_Put_Edit_StudInfo,
    API_Get_Basic_SchBaseList,
    API_Get_Basic_SchSubList,
    API_Get_Basic_DeptInfo,
    API_POST_Export_ClassBase,
    API_POST_Export_UnWishStu,
    API_POST_Export_WishStuCost,
    API_POST_Export_SingUpStu,
    API_POST_Export_Committee
} from '../../global/constants';
import moment from 'moment';
import DatePicker4Tw from "../../js/DatePicker4Tw";
import { checkAuthToken } from '../../global/TokenUtil';
import { UrlDecode } from '../../global/ToolUtil';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import QBankAdminAccManage from './QBankAdminAccManage';


function QBankAdminLayout({ match}) {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const [usDB, setUSDB] = useState({});

    //環境數值
    const [curSemester, setCurSemester] = useState("");//112
    const [curAvaStart, setCurAvaStart] = useState("");//2023/5/1
    const [curAvaEnd, setCurAvaEnd] = useState("");//2023/5/30
    const [curWishLength, setCurWishLength] = useState("");//6
    const [curAvaStartTime, setCurAvaStartTime] = useState("");//12:00
    const [curAvaEndTime, setCurAvaEndTime] = useState("");//12:00
    const [editSeme, setEditSeme] = useState(false);// 編輯 學年 / 系統開放時間
    const [turnOff, setTurnOff] = useState(true);//啟用畫面按鈕
    const [typeFileDot, setTypeFileDot] = useState([
        ".xlsx", ".xlsm", ".xlsb", ".xltx", ".xltm", ".xls", ".xlt", ".xls", ".xml", ".xlam", ".xla", ".xlw", ".xlr"
    ])//可使用檔案類型 .doc
    const [typeFile, setTypeFile] = useState(
        ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ])//可使用檔案類型 file type
    const [fileMaxSize, setFileMaxSize] = useState(5368709120);//檔案尺寸上限

    //學校
    const [schBaseList, setSchBaseList] = useState([]);//學校清單
    const [schCata, setSchCata] = useState([]);//學校所屬科系
    const [schWish, setSchWish] = useState("");//學校可報名數量
    //搜索
    const [schoolCode, setSchoolCode] = useState("");//選擇學校
    const [schoolSearch, setSchoolSearch] = useState({});//匹配搜索學校
    //考試類群代碼
    const [kindCode, setKindCode] = useState([]);
    //設定新學期
    const [newSemester, setNewSemester] = useState("");//新學期年度
    const [startDate, setStartDate] = useState("");//選填 開始日期
    const [endDate, setEndDate] = useState("");//選填 結束日期
    const [startTime, setStartTime] = useState("00:00");//選填 開始時間
    const [endTime, setEndTime] = useState("00:00");//選填 結束時間
    const [wishLength, setWishLength] = useState("");//可選填數量
    const [semOption, setSemOption] = useState([]);//可選新學期
    //查詢學生
    const [checkStuNo, setCheckStuNo] = useState("");//學號
    const [getStuDetail, setGetStuDetail] = useState({});//學生資料
    //上傳類型
    const [putDirct, setPutDirct] = useState("");
    const [excel, setExcel] = useState("");

    useEffect(() => {
        if (localStorage.getItem('isLogin')) {
            //查驗token
            if (jwtCheck()) {
                let usDB = JSON.parse(localStorage.getItem('UserData'));
                console.log(usDB);
                if (!usDB) {

                } else {
                    jwtToSet(usDB);
                    setTurnOff(false);
                }
            }
        } else {
            history.push("/")
        }

        let rocY = new Date().getFullYear() - 1911;
        if (rocY > 0 && rocY < 1000) {
            setSemOption([rocY - 1, rocY, rocY + 1])
        }
        getData();
        getDeptInfo();
    }, []);

    //token解析
    function jwtCheck(inputToken) {
        let qqq = localStorage.getItem("token").split(".");
        if (inputToken) {
            qqq = inputToken.split(".");
        }
        try {
            var arq = JSON.parse(decodeURIComponent(escape(window.atob(qqq[1].replace(/-/g, "+").replace(/_/g, "/")))));
            if (arq && Object.keys(arq)) {
                if (arq.type != "admin") {
                    alert("無效登入");
                    localStorage.clear();
                    history.push("/");
                    return false;
                }
            } else {
                throw "無效token"
            }
        } catch (e) {
            localStorage.clear();
            alert("登入失效");
            window.location.href = "/";
            return false;
        }
        return true;
    }
    //token轉譯後存容器
    function jwtToSet(token) {
        setUSDB(token);
        setCurSemester(token.ExamNo);
        if (token.ExSchdStartDate && token.ExSchdEndDate) {
            if (moment(token.ExSchdStartDate).isValid() && moment(token.ExSchdEndDate).isValid()) {
                setCurAvaStart(moment(token.ExSchdStartDate).format("YYYY/MM/DD"));
                setCurAvaEnd(moment(token.ExSchdEndDate).format("YYYY/MM/DD"));

                setCurAvaStartTime(moment(token.ExSchdStartDate).format("HH:mm"))
                setCurAvaEndTime(moment(token.ExSchdEndDate).format("HH:mm"))
            }
        }
        setCurWishLength(token.ExamWish);
    }

    //查詢學校資料
    function getData() {
        if (turnOff) { return null; }
        API_Get_Basic_SchBaseList()
            .then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    setSchBaseList(data.result);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //時間輸入
    function upDateSemester(e, vName) {
        if (moment(e).format() == "Invalid date") { return null; }
        if (!moment(e).isValid()) { return null; }
        if (vName == "StartDate") {
            setStartDate(moment(e).format("YYYY/MM/DD"))
            //let posYear = parseInt(moment(e).format("YYYY")) - 1911;//更新可選學年度
            //if (posYear > 2 && posYear < 1000) {
            //    let yay = [posYear, (posYear + 1)];
            //    console.log(newSemester, yay)
            //    if (!newSemester || !yay.includes(parseInt(newSemester))) {
            //        setNewSemester("");
            //    }
            //    setSemOption(yay);
            //    setStartDate(moment(e).format("YYYY/MM/DD"))
            //}
        } else if (vName == "EndDate") {
            setEndDate(moment(e).format("YYYY/MM/DD"))
        }
    }

    //時間檢查
    function timeValidate() {
        if (!/^\d{4}\/\d{2}\/\d{2}$/.test(startDate)) { return "請設定開始選填時間"; }
        if (!/^\d{4}\/\d{2}\/\d{2}$/.test(endDate)) { return "請設定結束選填時間"; }
        let aq = moment(startDate + " " + startTime), eq = moment(endDate + " " + endTime);
        if (!aq.isValid() || !eq.isValid()) { return "時間格式錯誤" }
        if (aq.isAfter(eq, "second") || eq.isSame(aq)) { return "開始時間必須在結束之前"; }
        //if (eq.diff(aq, "days") > 367) { return "時間區間不能超過一年"; }
        return true;
    }

    //更新 新學期
    function putNewYear() {
        //驗證機制區
        if (!/^\d{2,3}$/.test(newSemester)) { return alert("請設定新學期年度"); }
        if (!semOption.includes(parseInt(newSemester))) { return alert("限制使用可選定學期年度"); }
        let arq = timeValidate();
        if (arq !== true) { return alert(arq); }
        if (!/^\d{1,2}$/.test(wishLength) || wishLength < 1 || wishLength > 10) { return alert("請設定可選填數量1~9"); }
        var x = confirm("確認新增學年度，將刪除舊學年`全部`資料");
        if (!x) { return null; }
        //fake api function for demo
        //setCurSemester(newSemester);
        //setCurAvaStart(startDate);
        //setCurAvaEnd(endDate);
        //setCurWishLength(wishLength);
        document.getElementById("loading").style.display = "block";
        //呼叫api
        API_POST_Year_Add({
            "examSchYear": newSemester,
            "examWish": wishLength,
            "exSchdStartDate": moment(startDate).format("YYYY-MM-DD") + "T" + startTime,
            "exSchdEndDate": moment(endDate).format("YYYY-MM-DD") + "T" + endTime
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/";
                }
                console.log(data);
                if (data.code == "0000") {
                    if (jwtCheck(data.token.token)) {
                        var UserData = data.result
                        if (UserData) {
                            let newUSDB = {
                                "ExamNo": UserData.examSchYear,
                                "ExSchdStartDate": UserData.exSchdStartDate,
                                "ExSchdEndDate": UserData.exSchdEndDate,
                                "ExamWish": UserData.examWish
                            }
                            localStorage.setItem('UserData', JSON.stringify(newUSDB));
                            jwtToSet(newUSDB);
                            setTurnOff(false);
                            alert("更新學年度");
                            callHide();
                            setNewSemester("");
                            setStartDate("");
                            setEndDate("");
                            setWishLength("");
                        }
                    }
                } else {
                    alert(data.message);
                }
                document.getElementById("loading").style.display = "none";
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setTurnOff(true);
                document.getElementById("loading").style.display = "none";
            })
    }

    //更新 學期開放時間
    function updateCurTime() {
        let arq = timeValidate();
        if (arq !== true) { return alert(arq); }
        API_Put_Year_EditTime({
            "Year": curSemester,
            "StartDate": moment(startDate).format("YYYY-MM-DD") + "T" + startTime,
            "EndDate": moment(endDate).format("YYYY-MM-DD") + "T" + endTime
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/";
                }
                console.log(data);
                if (data.code == "0000") {
                    if (jwtCheck(data.token.token)) {
                        let newUSDB = {
                            "ExamNo": data.result.year,
                            "ExSchdStartDate": data.result.startDate,
                            "ExSchdEndDate": data.result.endDate,
                            "ExamWish": curWishLength
                        }
                        localStorage.setItem('UserData', JSON.stringify(newUSDB));
                        jwtToSet(newUSDB);
                        setTurnOff(false);
                        alert("儲存成功");
                        callHide();
                        setNewSemester("");
                        setStartDate("");
                        setEndDate("");
                        setWishLength("");
                    }
                } else {
                    alert(data.message);
                }
                document.getElementById("loading").style.display = "none";
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    window.location.href = "/";
                }
                setTurnOff(true);
                document.getElementById("loading").style.display = "none";
            })
    }

    //匯入資料 呼叫
    function importFile(target) {
        document.getElementById("fileContainer").value = "";
        switch (target) {
            case "Student":
                setPutDirct("Student");
                break;
            case "School":
                setPutDirct("School");
                break;
            case "SchSubBase":
                setPutDirct("SchSubBase");
                break;
            case "DeptBase":
                setPutDirct("DeptBase");
                break;
            default:
                console.log("useless target")
                return setPutDirct(""), setExcel("");
        }
        document.getElementById("fileContainer").click();
    }

    //選擇檔案
    async function openFile(e) {
        if (turnOff) { return null; }
        if (e.target.files.length > 0) {
            let fileDoc = event.target.files[0];

            if (fileDoc.size > fileMaxSize) {
                e.target.value = "";
                return alert("上傳檔案過大")
            }
            if (!typeFile.includes(fileDoc.type)) {
                e.target.value = "";
                return alert("請選擇excel檔案")
            }

            await putFile(fileDoc);
            e.target.value = "";

        }
    }

    //上傳檔案
    async function putFile(fileDLC) {
        document.getElementById("fileContainer").value = "";
        var loading = document.getElementById('loading');
        loading.style.display = "block";
        if (!fileDLC && Object.keys(fileDLC).length == 0) {
            alert("記憶體空間不足寫入");
            return loading.style.display = "none";
        }

        //傳送資料容器
        const formData = new FormData();
        //參數資料類
        //var dt = {
        //    productId: productNameId,
        //    fileList: uploadFileNameArray
        //}
        //formData.append("參數", 資料);
        //formData.append("view", JSON.stringify(dt));
        formData.append("File", fileDLC);

        //傳送資料封包
        const requestOptions = {
            method: "POST",
            headers: {
                //"Content-Type": "multipart/form-data", //default maxiuam post 5MB
                Authorization: localStorage.getItem("token"),
            },
            body: formData,
        };

        //前往api分配
        let apiDirect = "", respondTxt = "";
        if (putDirct == "Student") {
            apiDirect = "/BasicInfo/ImportStudent";
            respondTxt = "匯入學生資料成功";
        }
        else if (putDirct == "School") {
            apiDirect = "/BasicInfo/ImportSch";
            respondTxt = "匯入學校資料成功";
        }
        else if (putDirct == "SchSubBase") {
            apiDirect = "/BasicInfo/ImportSchSubBase";
            respondTxt = "匯入學校科系成功";
        }
        else if (putDirct == "DeptBase") {
            apiDirect = "/BasicInfo/ImportDeptBase";
            respondTxt = "匯入統測類群代碼表成功";
        }
        else {
            loading.style.display = "none";
            return alert("無效請求")
        }

        //呼叫api
        var path = window.apiUrl + apiDirect;
        fetch(path, requestOptions)
            .then((response) => response.json())
            .then((data) => {

                console.log(data);
                if (data.code == "0000") {
                    alert(respondTxt);
                } else if (data.code == "1001") {
                    alert("登入驗證失效");
                    localStorage.clear();
                    return window.location = "/";
                } else {
                    alert("匯入失敗，請重新匯入");
                    console.log(data.message);
                }
                loading.style.display = "none";
            })
            .catch((err) => {
                alert("資料庫寫入失敗");
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
                loading.style.display = "none";
            });
    }

    //查詢 by 學號 get 學生資料
    function studInfo() {
        if (turnOff) { return null; }
        API_Get_Basic_StudInfo({
            "StudNo": checkStuNo
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    if (data.result.length > 0) {
                        if (Object.keys(data.result[0]).length > 0) {
                            console.log(data.result[0]);
                            return setGetStuDetail(data.result[0]);
                        }
                    }
                }
                else {
                    alert(data.message);
                }

                setGetStuDetail({});
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
                setGetStuDetail({});
            });

    }

    //編輯 - 學生資料
    function editStuDetail(e, vName) {

        const updatedStuDetail = {
            ...getStuDetail,
            [vName]: e
        };
        setGetStuDetail(updatedStuDetail);

        //"StudClass"
        //"StudSeat"
        //"StudName"
        //"StudIDNo"
        //"StudDeptNo"
    }
    //更新 - 學生資料
    function saveStudent() {
        console.log(getStuDetail);
        if (!getStuDetail.StudID) { return alert("無效學號") }
        if (!getStuDetail.StudNo) { return alert("無效學號") }
        if (!getStuDetail.StudName) { return alert("無效學生名字") }
        if (!getStuDetail.StudIDNo) { return alert("無效身分證") }
        if (!getStuDetail.StudDeptNo) { return alert("無效類組") }

        API_Put_Edit_StudInfo({
            "studID": getStuDetail.StudID,
            "studName": getStuDetail.StudName,
            "studIDNo": getStuDetail.StudIDNo,
            "studDeptNo": getStuDetail.StudDeptNo
        }).then((response) => response.data)
            .then((data) => {
                //checkAuthToken(data);

                console.log(data);
                if (data.code == "0000") {
                    alert("更新成功");
                    //callHide();
                }
                else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //查詢學校
    function getSchCheck() {
        if (turnOff) { return null; }
        //console.log(schoolSearch)
        if (Object.keys(schoolSearch).length == 0) { return alert("請選擇清單內資料") }
        API_Get_Basic_SchSubList({
            "SchNo": schoolSearch.schNo
        }).then((response) => response.data)
            .then((data) => {

                console.log(data);
                if (data.code == "0000") {
                    setSchCata(data.result);
                    setSchWish(schoolSearch.schWishCnt);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    //檢查 查詢學校代碼有效
    useEffect(() => {
        let gotYou = schBaseList.find(x => {
            if (x.schNo + " " + x.schName == schoolCode) {
                return true;
            }
        });
        if (gotYou && typeof gotYou === "object") {
            setSchoolSearch(gotYou);
        } else {
            setSchoolSearch({})
        }

    }, [schoolCode]);

    //查詢類群代碼
    function getDeptInfo(type) {
        if (turnOff) { return null; }
        API_Get_Basic_DeptInfo()
            .then((response) => response.data)
            .then((data) => {

                console.log(data);
                if (data.code == "0000") {
                    setKindCode(data.result);
                    if (!type) {
                        return true;
                    }
                } else {
                    alert(data.message);
                }
                if (!type) {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //匯出檔案
    function exportFile(target) {
        if (turnOff) { return null; }
        if (!target) { return alert("無效下載目標") }
        let fileName = "";
        const FileDownload = require("js-file-download");
        console.log(target)
        if (target == "ClassBase") {
            fileName = "匯出班級帳號密碼.xlsx";
            API_POST_Export_ClassBase({
                "rptCode": "ClassBase"
            })
                .then((response) => {
                    console.log(response);
                    //const disposition = response.headers['content-disposition'];
                    //console.log(disposition)
                    //var filename = disposition.split("''")[1];
                    //FileDownload(response.data, UrlDecode(filename));
                    FileDownload(response.data, fileName);
                })
                .catch(err => {
                    console.log(err);
                    alert("無檔案可下載");
                })
        }
        else if (target == "UnWishStu") {
            fileName = "未填寫志願學生列表.xlsx";
            API_POST_Export_UnWishStu({
                "rptCode": "UnWishStu"
            })
                .then((response) => {
                    console.log(response);
                    FileDownload(response.data, fileName);
                })
                .catch(err => {
                    console.log(err);
                    alert("無檔案可下載");
                })
        }
        else if (target == "WishStuCost") {
            fileName = "學生報名收費一覽表.xlsx";
            API_POST_Export_WishStuCost({
                "rptCode": "StuCost"
            })
                .then((response) => {
                    console.log(response);
                    FileDownload(response.data, fileName);
                })
                .catch(err => {
                    console.log(err);
                    alert("無檔案可下載");
                })
        }
        else if (target == "SingUpStu") {
            fileName = "學生統測報名資料檔.xlsx";
            API_POST_Export_SingUpStu({
                "rptCode": "SingUpStu"
            })
                .then((response) => {
                    console.log(response);
                    FileDownload(response.data, fileName);
                })
                .catch(err => {
                    console.log(err);
                    alert("無檔案可下載");
                })
        }
        else if (target == "Committee") {
            fileName = "委員會報名用檔.xlsx";
            API_POST_Export_Committee({
                "rptCode": "Committee"
            })
                .then((response) => {
                    console.log(response);
                    FileDownload(response.data, fileName);
                })
                .catch(err => {
                    console.log(err);
                    alert("無檔案可下載");
                })
        }
    }

    //清除系所查詢
    function clearSchCheck() {
        setSchCata([]);
        setSchWish("");
        setSchoolCode(null);
        onClear();
    }
    //主動清除下拉選單
    const selectInputRef = useRef();
    const onClear = () => {
        selectInputRef.current.clearValue();
    }


    //呼叫選單
    function callStudent(target) {
        var isUpdate = getDeptInfo("student");
        setGetStuDetail({});
        setCheckStuNo("");
        if (isUpdate) {
            $("#sys_checkStu").modal("show");
        }
    }
    function callSchool() {
        getData();
        clearSchCheck();
        $("#sys_checkSch").modal("show");
    }
    function callKind() {
        var isUpdate = getDeptInfo("cata");
        if (isUpdate) {
            $("#sys_checkKind").modal("show");
        }
    }
    function callSemester(type) {
        if (type && type === "Edit") {
            setEditSeme(true);
            setStartDate(curAvaStart);
            setEndDate(curAvaEnd);
            setStartTime(curAvaStartTime);
            setEndTime(curAvaEndTime);
        } else {
            setStartDate("");
            setEndDate("");
            setStartTime("00:00");
            setEndTime("00:00");
            setEditSeme(false);
        }
        $("#sys_openSemester").modal("show");
    }

    //隱藏選單
    function callHide() {
        $(".modal").modal("hide");
    }

    return (
        <div className="row g-0">
            <div className="col-2 bg-dark">
                <div className="vh-100">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    帳號管理
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body p-0">
                                    <div className="accordion" id="nestedAccordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="nestedHeadingOne">
                                                <button className="accordion-button bg-warning text-light ps-5" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseOne">
                                                    建立帳號
                                                </button>
                                            </h2>
                                            <div id="nestedCollapseOne" className="accordion-collapse collapse" aria-labelledby="nestedHeadingOne" data-bs-parent="#nestedAccordion">
                                                <div className="accordion-body ps-0">
                                                    <Link className="d-block w-100 py-3 px-5 text text-decoration-none" >學生志願選填</Link>       
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="nestedHeadingOne2">
                                                <button className="accordion-button bg-warning text-light ps-5" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseOne2">
                                                    重置密碼
                                                </button>
                                            </h2>
                                            <div id="nestedCollapseOne2" className="accordion-collapse collapse" aria-labelledby="nestedHeadingOne" data-bs-parent="#nestedAccordion">
                                                <div className="accordion-body ps-5">
                                                    123
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                                    考卷維護
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse"  data-bs-parent="#accordionExample">
                                <div className="accordion-body p-0">
                                    <div className="accordion" id="nestedAccordionTwo">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="nestedHeadingTwo">
                                                <button className="accordion-button bg-warning text-light ps-5" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseTwo">
                                                    科別維護
                                                </button>
                                            </h2>
                                            <div id="nestedCollapseTwo" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionTwo">
                                                <div className="accordion-body ps-5">
                                                    123
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="nestedHeadingTwo2">
                                                <button className="accordion-button bg-warning text-light ps-5" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseTwo2">
                                                    考試類別維護
                                                </button>
                                            </h2>
                                            <div id="nestedCollapseTwo2" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionTwo">
                                                <div className="accordion-body ps-5">
                                                    456
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="nestedHeadingTwo3">
                                                <button className="accordion-button bg-warning text-light ps-5" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseTwo3">
                                                    教師類別維護
                                                </button>
                                            </h2>
                                            <div id="nestedCollapseTwo3" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionTwo">
                                                <div className="accordion-body ps-5">
                                                    456
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="nestedHeadingTwo4">
                                                <button className="accordion-button bg-warning text-light ps-5" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseTwo4">
                                                    哭哭類別維護
                                                </button>
                                            </h2>
                                            <div id="nestedCollapseTwo4" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionTwo">
                                                <div className="accordion-body ps-5">
                                                    456
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-10">
                {/*<Route path="/account_manage" component={QBankAdminAccManage} />*/}
                
            </div>
            
        </div>
    );
}


export default QBankAdminLayout;
