import "../../js/jquery.min.js";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
    API_GetAll_TeacherTrainingCategory,
    API_Get_TeacherTrainingAccount_UserView,
    API_ChangePassword_TeacherTrainingAccount,
    API_Create_TeacherTraining,
    API_GetAll_TeacherTraining,
    API_Download_TeacherTraining,
    API_Delete_TeacherTraining,
    API_GetAll_TeacherTrainingAcademicYear,
    API_Download_TeacherTrainingEmptyForm
} from '../../global/constants';
import Modal from '../../global/Modal';
import useModal from '../../global/useModal';

function SeminarTeacher() {
    const { activeModal, openModal, closeModal } = useModal();

    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const trainingThoughtsFileInputRef = useRef();
    const trainingDataFileInputRef = useRef();

    const [isReGet, setIsReGet] = useState(false);
    const [teacherTrainingAccountId, setTeacherTrainingAccountId] = useState(null);
    const [trainingAccount, setTrainingAccount] = useState(null);

    const [isResettingPwd, setIsResettingPwd] = useState(false);

    const [inputOldPassword, setInputOldPassword] = useState("");
    const [inputNewPassword, setInputNewPassword] = useState("");
    const [inputNewPasswordCheck, setInputNewPasswordCheck] = useState("");

    const [academicYears, setAcademicYears] = useState([]);
    const [teacherTrainingCategories, setTeacherTrainingCategories] = useState([]);

    const [academicYearSelected, setAcademicYearSelected] = useState(null);
    const [teacherTrainingCategorySelected, setTeacherTrainingCategorySelected] = useState(null);
    const [trainingBeginDate, setTrainingBeginDate] = useState(null);
    const [trainingEndDate, setTrainingEndDate] = useState(null);
    const [trainingHour, setTrainingHour] = useState(null);

    const [paperIllustrate, setPaperIllustrate] = useState([]);

    const [trainingThoughtsFile, setTrainingThoughtsFile] = useState(null);
    const [trainingDataFileFile, setTrainingDataFileFile] = useState(null);

    const [dataList, setDataList] = useState([]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        console.log(name, value);

        switch (name) {
            case 'inputOldPassword':
                setInputOldPassword(value);
                break;
            case 'inputNewPassword':
                setInputNewPassword(value);
                break;
            case 'inputNewPasswordCheck':
                setInputNewPasswordCheck(value);
                break;
            case 'paperIllustrate':
                setPaperIllustrate(value);
                break;
            case 'academicYearSelected':
                setAcademicYearSelected(value);
                break;
            case 'teacherTrainingCategorySelected':
                setTeacherTrainingCategorySelected(value);
                break;
            case 'trainingBeginDate':
                setTrainingBeginDate(value);
                break;
            case 'trainingEndDate':
                setTrainingEndDate(value);
                break;
            case 'trainingHour':
                setTrainingHour(value);
                break;
            case 'examCatrgorySelected':
                setExamCatrgorySelected(value);
                break;
            case 'subjectSelected':
                setSubjectSelected(value);
                break;
            default:
                break;
        }
    }

    const handleTrainingSubmit = () => {
        var dt = {
            "teacherTrainingAccountId": teacherTrainingAccountId,
            "academicYear": academicYearSelected,
            "teacherTrainingCategory": teacherTrainingCategorySelected,
            "trainingBeginDate": trainingBeginDate,
            "trainingEndDate": trainingEndDate,
            "trainingHour": trainingHour,
            "paperIllustrate": paperIllustrate,
        };

        let errorStr = "";

        if (academicYearSelected == null || academicYearSelected == "") {
            errorStr += "學年度必填！\r\n"
        }

        if (teacherTrainingCategorySelected == null || teacherTrainingCategorySelected == "") {
            errorStr += "研習類別必填！\r\n"
            return;
        }

        if (trainingBeginDate == null || trainingBeginDate == "") {
            errorStr += "研習日期(起)必填！\r\n"
        }


        if (trainingHour == null || trainingHour == "") {
            errorStr += "研習時數必填！\r\n"
        }

        if (paperIllustrate == null || paperIllustrate == "") {
            errorStr += "研習內容必填！\r\n"
        }

        if (errorStr != "") {
            alert(errorStr);
            return;
        }


        console.log("預覽送出資料", [dt, trainingThoughtsFile, trainingDataFileFile]);

        API_Create_TeacherTraining(dt, trainingThoughtsFile, trainingDataFileFile)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    alert("新增成功！");
                    setIsReGet(true);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                if (err.response.status === 400) {
                    alert("客戶端錯誤，請檢查必填欄位！");

                }
                else {
                    console.log(err);
                    alert("未知錯誤！");

                }
            })
    }

    const handleChangePassword = () => {
        setIsResettingPwd(true)
        openModal("changePassword");
    }


    const handleChangePasswordSumit = () => {
        if (inputNewPassword != inputNewPasswordCheck) {
            alert("新密碼和密碼確認不一致，請重新確認");
            return;
        }
        console.log("重設資料Log", [inputOldPassword, inputNewPassword, inputNewPasswordCheck]);

        var dt = {
            id: teacherTrainingAccountId,
            oldPassword: inputOldPassword,
            newPassword: inputNewPassword
        }

        API_ChangePassword_TeacherTrainingAccount(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    alert("密碼更改成功！");
                    setInputOldPassword("");
                    setInputNewPassword("");
                    setInputNewPasswordCheck("");
                    setIsResettingPwd(false);
                    closeModal();
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    // localStorage.clear();
                    // history.push('/');
                }
            })

    }

    const handleFileChange = (event, ref, setFile) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file) {
                const fileType = file.type;
                const validTypes = [
                    'application/pdf'
                ];
                if (!validTypes.includes(fileType)) {
                    alert('不支持的文件類型');

                    if (ref.current) {
                        ref.current.value = '';
                    }
                    return;
                }

                setFile(file);
            }
        }
    };

    const handleQuestionDownload = (filename, sourceName) => {
        const FileDownload = require("js-file-download");

        API_Download_TeacherTraining({
            "fileName": filename,
            "sourceName": sourceName
        })
            .then((response) => {
                console.log(response);
                FileDownload(response.data, sourceName);
            })
            .catch(err => {
                console.log(err);
                alert("無檔案可下載");
            })
    }

    const handleTrainingEmptyForm = () => {
        const FileDownload = require("js-file-download");

        API_Download_TeacherTrainingEmptyForm()
            .then((response) => {
                console.log(response);
                FileDownload(response.data, "研習心得分享單.docx");
            })
            .catch(err => {
                console.log(err);
                alert("無檔案可下載");
            })
    }

    const handleDeleteTeacherTraining = (id) => {
        var dt = {
            id: id,
        }

        API_Delete_TeacherTraining(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setIsReGet(true);

                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    const getTeacherTrainingAccount = (id) => {
        var dt = {
            id: id
        }

        API_Get_TeacherTrainingAccount_UserView(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    let entity = data.result;
                    if (entity == null) {
                        alert("帳號不存在")
                    }
                    else {
                        setTrainingAccount(data.result);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    // localStorage.clear();
                    // history.push('/');
                }
            })

    }


    function getTeacherTrainingCategory() {
        API_GetAll_TeacherTrainingCategory()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setTeacherTrainingCategories(data.result);
                        setTeacherTrainingCategorySelected(data.result[0].name);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                alert("未知錯誤");

            })
    }

    function getAcademicYear() {
        API_GetAll_TeacherTrainingAcademicYear()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result != null && data.result.length > 0) {
                        setAcademicYears(data.result);
                        setAcademicYearSelected(data.result[0].name);
                    }
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }




    function getData(id) {

        let dt = {
            accountId: id
        }

        API_GetAll_TeacherTraining(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setDataList(data.result);
                } else {
                    alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    // localStorage.clear();
                    // history.push('/');
                }
            })
    }

    function tokenHandle() {
        if (localStorage.getItem("isLogin") != "1") {
            alert("登入已失效")
            localStorage.clear();
            return window.location.href = "/";
        }
        //查驗token
        let qqq = localStorage.getItem("token").split(".");
        try {
            var arq = JSON.parse(decodeURIComponent(escape(window.atob(qqq[1].replace(/-/g, "+").replace(/_/g, "/")))));
            if (arq && Object.keys(arq)) {
                if (arq.type == "teacherTrainingAccount") {
                    setTeacherTrainingAccountId(arq.id);
                }
                else {
                    localStorage.clear();
                    alert("無效登入");
                    return history.push("/");
                }
            } else {
                throw "無效token"
            }
        } catch (e) {
            localStorage.clear();
            alert("登入失效");
            return window.location.href = "/";
        }
    }

    useEffect(() => {
        tokenHandle()
        getTeacherTrainingCategory();
        getAcademicYear();
    }, []);

    useEffect(() => {
        if (teacherTrainingAccountId != null) {
            getTeacherTrainingAccount(teacherTrainingAccountId);
            getData(teacherTrainingAccountId);
        }
    }, [teacherTrainingAccountId])

    useEffect(() => {
        if (trainingAccount != null && isResettingPwd == false) {
            if (trainingAccount.isNeedResetPassword == true) {
                alert("首次登入或密碼已被重置，請重設密碼！");
                handleChangePassword();
            }
        }
    }, [trainingAccount])

    useEffect(() => {
        if (isReGet == true) {
            getData(teacherTrainingAccountId);
            setIsReGet(false);
        }
    }, [isReGet])

    return (
        <>
            <div className="container mt-4">
                <h2>臺北市立松山家商心得上傳系統</h2>
                <div className="teacher-info-management mt-4 d-flex justify-content-between align-items-center">
                    <p>教師名稱: {trainingAccount == null ? "" : trainingAccount.name}</p>
                    <div className="d-flex">
                        {/* <button className="btn btn-outline-primary">心得檢索系統</button> */}
                        <button className="btn btn-outline-danger ms-4" onClick={handleChangePassword}>更改密碼</button>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="row row-cols-3">
                        <div className="col">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5 className="card-title">Step1.研習內容基本設定</h5>
                                    <select className="form-select mb-2" aria-label="Default select example" value={academicYearSelected} onChange={handleInputChange} name={"academicYearSelected"}>
                                        <option defaultValue="" disabled>學年度</option>
                                        {academicYears.map((item, index) => <option value={item.name}>{item.name}</option>)}
                                    </select>
                                    <div class="form-floating mb-2">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="trainingBeginDate"
                                            value={trainingBeginDate}
                                            onChange={handleInputChange}
                                            placeholder="年/月/日"
                                            name="trainingBeginDate"
                                        />
                                        <label for="trainingBeginDate">研習日期(起)</label>
                                    </div>
                                    <div style={{ textAlign: "right", color: "red" }}>(必填)</div>

                                    <div class="form-floating mb-2">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="trainingEndDate"
                                            value={trainingEndDate}
                                            onChange={handleInputChange}
                                            placeholder="年/月/日"
                                            name="trainingEndDate"
                                        />
                                        <label for="trainingEndDate">研習日期(迄)</label>
                                    </div>
                                    <div style={{ textAlign: "right", color: "red" }}>(必填)</div>

                                    <select className="form-select mb-2" aria-label="Default select example" value={teacherTrainingCategorySelected} onChange={handleInputChange} name={"teacherTrainingCategorySelected"}>
                                        <option selected disabled>研習類別</option>
                                        {teacherTrainingCategories.map((item, index) => <option value={item.name}>{item.name}</option>)}
                                    </select>
                                    <div class="form-floating mb-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="trainingHour"
                                            placeholder="研習核准時數(小時)"
                                            value={trainingHour}
                                            onChange={handleInputChange}
                                            name="trainingHour"
                                        />
                                        <label for="trainingHour">研習核准時數(小時)</label>
                                    </div>
                                    <div style={{ textAlign: "right", color: "red" }}>(必填)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5 className="card-title">Step2.概述</h5>
                                    <label for="paperIllustrate" className="form-label">研習內容概述</label>
                                    <textarea
                                        placeholder="例如:數學,啟芳,第三冊,1-1~1-4,直線方程式"
                                        className="form-control"
                                        id="paperIllustrate"
                                        rows="8"
                                        value={paperIllustrate}
                                        onChange={handleInputChange}
                                        name="paperIllustrate"
                                    >
                                    </textarea>
                                    <div style={{ textAlign: "right", color: "red" }}>(必填)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5 className="card-title">Step3.上傳研習心得或研習資料</h5>
                                    <div style={{ textAlign: "right", color: "red" }}>(檔案限5MB、限PDF檔)</div>
                                    
                                    <label for="formSeminarFile" className="form-label">研習心得:</label>
                                    <input className="form-control mb-3" type="file" ref={trainingThoughtsFileInputRef} onChange={(e) => handleFileChange(e, trainingThoughtsFileInputRef, setTrainingThoughtsFile)} />
                                    <div style={{ textAlign: "right", color: "red" }}>(必填)</div>

                                    <label for="formDataFile" className="form-label">研習資料:</label>
                                    <input className="form-control mb-5" type="file" ref={trainingDataFileInputRef} onChange={(e) => handleFileChange(e, trainingDataFileInputRef, setTrainingDataFileFile)} />

                                    <button type="button" class="btn btn-link" onClick={handleTrainingEmptyForm}>研習心得表單下載</button>

                                </div>
                                <div className="card-footer border-0 bg-white">
                                    <button class="btn btn-primary w-100 p-3" type="submit" onClick={handleTrainingSubmit}>確認送出</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
                <div className="mt-4">
                    <p className="mb-3">{trainingAccount == null ? "" : trainingAccount.name}老師，你已上傳的研習心得如下:</p>
                    <table class="table table-striped table-hover table-bordered table-bordered-dark">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col">編號</th>
                                <th scope="col">學年度</th>
                                <th scope="col">研習類別</th>
                                <th scope="col">研習日期</th>
                                <th scope="col">研習時數</th>
                                <th scope="col">研習心得</th>
                                <th scope="col">研習資料</th>
                                <th scope="col">研習內容概述</th>
                                <th scope="col">刪除</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.academicYear}</td>
                                    <td>{item.teacherTrainingCategory}</td>
                                    <td>{item.trainingBeginDate} {item.trainingEndDate == "" || item.trainingEndDate == null ? "" : " ~ " + item.trainingEndDate}</td>
                                    <td>{item.trainingHour}</td>
                                    <td><a href="#" onClick={() => handleQuestionDownload(item.trainingThoughtsFilePath, item.trainingThoughtsFileName)}>{item.trainingThoughtsFilePath == null ? '' : '研習心得'}</a></td>
                                    <td><a href="#" onClick={() => handleQuestionDownload(item.trainingDataFilePath, item.trainingDataFileName)}>{item.trainingDataFilePath == null ? '' : '研習資料'}</a></td>
                                    <td>{item.paperIllustrate}</td>
                                    <td><a href="#" onClick={() => handleDeleteTeacherTraining(item.id)}>X</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal isActive={activeModal === 'changePassword'} labelledby={"changePassword"}>
                <div className="modal-header align-items-start py-0 border-0 mx-0">
                    <h3>更改密碼</h3>
                </div>
                <div className="modal-body pt-0">
                    <div className="">
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                className="form-control"
                                id="inputOldPassword"
                                placeholder="123456"
                                value={inputOldPassword}
                                onChange={handleInputChange}
                                name="inputOldPassword"
                            />
                            <label htmlFor="inputOldPassword">原密碼</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                className="form-control"
                                id="inputNewPassword"
                                placeholder="123456"
                                value={inputNewPassword}
                                onChange={handleInputChange}
                                name="inputNewPassword"
                            />
                            <label htmlFor="inputNewPassword">新密碼</label>
                        </div>
                        <div className="form-floating mb-5">
                            <input
                                type="password"
                                className="form-control"
                                id="inputNewPasswordCheck"
                                placeholder="123456"
                                value={inputNewPasswordCheck}
                                onChange={handleInputChange}
                                name="inputNewPasswordCheck"
                            />
                            <label htmlFor="inputNewPasswordCheck">密碼確認</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={closeModal}>關閉</button>
                    <button type="button" className="btn btn-primary" onClick={handleChangePasswordSumit}>送出</button>
                </div>
            </Modal>
        </>
    );
}


export default SeminarTeacher;
