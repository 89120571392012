import React from 'react';
import ReactDOM from 'react-dom';
import I18n from "./i18n"; //多國語言
import './index.css';
import './css/style.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import Header from './components/partial/header';
import Footer from './components/partial/footer';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from './pages/Home';
import back_Office from './pages/back_Office';
import teacher_Office from './pages/teacher_Office';
import student_Office from './pages/student_Office';
import Main from './pages/Main';
import QBankTeacher from './pages/question_bank/QBankTeacher';
import QBankSignIn from './pages/question_bank/QBankSignIn';
import QBankAdminLayout from './pages/question_bank/QBankAdminLayout';
import QBankAdminAccManage from './pages/question_bank/QBankAdminAccManage';
import QBankAdminExamInfo from './pages/question_bank/QBankAdminExamInfo';
import QBankSearchSystem from './pages/question_bank/QBankSearchSystem';
import SeminarSignIn from './pages/seminar/SeminarSignIn';
import SeminarTeacher from './pages/seminar/SeminarTeacher';
import SeminarAdminAccManage from './pages/seminar/SeminarAdminAccManage';
import SeminarAdminCategoryInfo from './pages/seminar/SeminarAdminCategoryInfo';
import SeminarSearchSystem from './pages/seminar/SeminarSearchSystem';
import SeminarSeminarAdminAccManageSearchSystem from './pages/seminar/SeminarSeminarAdminAccManageSearchSystem';

//const header = (
//    <Header />
//);
//const footer = (
//    <Footer />
//);
//ReactDOM.render(header, document.getElementById('master-header'));
//ReactDOM.render(footer, document.getElementById('master-footer'));

const root = (

    <I18n>

        <BrowserRouter>
            <Header />
            <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/volunteer" component={Home} />
                <Route exact path="/volunteer/back_Office" component={back_Office} />
                <Route exact path="/volunteer/teacher_Office" component={teacher_Office} />
                <Route exact path="/volunteer/student_Office" component={student_Office} />
                <Route exact path="/question_bank/signin" component={QBankSignIn} />
                <Route exact path="/question_bank/teacher" component={QBankTeacher} />
                
                
                <Route exact path="/question_bank/admin_exam_Info" component={QBankAdminExamInfo} />
                <Route exact path="/question_bank/admin_account_manage" component={QBankAdminAccManage} />
                <Route exact path="/question_bank/admin" component={QBankAdminLayout} />
                <Route exact path="/question_bank/seach_system" component={QBankSearchSystem} />
                <Route exact path="/seminar/signin" component={SeminarSignIn} />
                <Route exact path="/seminar/teacher" component={SeminarTeacher} />
                <Route exact path="/seminar/admin_account_manage" component={SeminarAdminAccManage} />
                <Route exact path="/seminar/admin_category_info" component={SeminarAdminCategoryInfo} />
                <Route exact path="/seminar/seach_system" component={SeminarSearchSystem} />
                <Route exact path="/seminar/admin_account_manage/seach_system" component={SeminarSeminarAdminAccManageSearchSystem} />
                
            </Switch>
            <Footer />
        </BrowserRouter >
    </I18n>

);

ReactDOM.render(root, document.getElementById('master-main'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
